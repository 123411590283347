import React, { useState } from 'react'
import ShippingCategory from '../components/category/ShippingCategory';
import LayoutSM3 from '../mvp2/03-05';

export const ShippingRender = () => {
    const [show, setShow] = useState('layoutSM3');
    const handleDynamicLayout=(data)=>{
      setShow(data);
    };
  return (
    <>
    <ShippingCategory dynamicLayout={handleDynamicLayout}/>
    {show === 'layoutSM3' && <LayoutSM3 />}
   </>
  )
}
