import React, { useState } from 'react'
import Layout8 from '../mvp2/03-38';
import SetManagementCategory from '../components/category/SetManagementCategory';
import Layout9 from '../mvp2/03-39';
import Layout10 from '../mvp2/03-40';

function SetManagementRender() {
    const [show, setShow] = useState('layout8');
    const handleDynamicLayout=(data)=>{
      setShow(data);
    };
  return (
    <>
     <SetManagementCategory dynamicLayout={handleDynamicLayout}/>
     {show === 'layout8' && <Layout8 />}
     {show === 'layout9' && <Layout9 />}
     {show === 'layout10' && <Layout10 />}
    </>
   )
}

export default SetManagementRender;