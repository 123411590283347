import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";

function Table() {
  const [dropdownOptions] = useState([
    { label: '削除', value: '' },
    { label: '処理を選択', value: 1 },
    { label: '返品入荷予定CSV出力', value: 2 },
    { label: 'バーコード印刷', value: 3 },
    { label: '手動格納', value: 4 },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全2件</span>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr className="h-[34px]">
                    <th className="border bg-gray-100 px-3 py-2 w-[150px] align-top font-medium">
                    発生日時
                    </th>
                    <th className="border bg-gray-100 px-3 py-2  w-[200px] align-top font-medium">
                    荷主名
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[200px] align-top font-medium">
                    種類 
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[200px] align-top font-medium">
                    設定名称
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[292px] align-top font-medium">
                    変更箇所
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[292px] align-top font-medium">
                    編集者
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    2024/02/09 11:49
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    ABC書店
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    印刷スケジュール
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    朝一作業分
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    印刷種類：抽出　→　バッチ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    倉庫管理者A
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    2024/02/09 11:12
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    Xアパレル
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    バッチルール
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    SDバッチ
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p> バッチ内分割（配送方法）：</p>
                      <p className="mt-1">分割する　→　分割しない</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    倉庫管理者A
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    2024/02/09 10:09
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    BBB雑貨
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    バッチルール
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    SDバッチ
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                     <p>バッチ内分割（配送方法）：</p>
                     <p className="mt-1">分割しない　→　分割する</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    倉庫管理者B
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        
    </div>
  );
}

export default Table;
