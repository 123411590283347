import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { CalendarDaysIcon } from '@heroicons/react/24/solid'
import '../../styles/datepicker.css';
import { registerLocale } from 'react-datepicker'
import { ja } from 'date-fns/locale/ja'
registerLocale('ja', ja)

export default function CustomPicker(props) {
  return (
    <div className="custom-react-datepicker-container w-full cursor-pointer">
      <DatePicker popperPlacement="bottom-start" locale="ja" className="custom-react-datepicker box-shadow-form h-9 w-full rounded border border-gray-300 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600 disabled:bg-gray-100" showIcon toggleCalendarOnIconClick icon={<CalendarDaysIcon className="text-gray-400" aria-hidden="true" />} placeholderText={props.option.placeholder} dateFormat="yyyy/MM/dd"/>
    </div>
  )
}