import React from 'react'
import Table from './table/Table'
// import Filter from './filter/index';

function LayoutNewCh4() {
  return (
    <>
    {/* <Filter /> */}
    <Table />
    </>
  )
}

export default LayoutNewCh4