import { useState } from "react";
import CustomPicker from "../../../components/layout/datepicker";
import CustomDropdown from "../../../components/layout/dropdown";
const Modal = ({ show, onClose, title, children }) => {
  const [dropdownOptions1] = useState([
    { label: '', value: 0 },
    { label: '荷主名', value: 1 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '', value: 0 },
    { label: '1回目', value: 1 },
  ]);
  const [dropdownOptions3] = useState([
    { label: '', value: 0 },
    { label: '9', value: 1 },
  ]);
  const [dropdownOptions4] = useState([
    { label: '', value: 0 },
    { label: '00', value: 1 },
  ]);
  const [dropdownOptions5] = useState([
    { label: '', value: 0 },
    { label: 'バッジ', value: 1 },
  ]);
  const [dropdownOptions6] = useState([
    { label: '', value: 0 },
    { label: 'バッジ名', value: 1 },
  ]);
  const [dropdownOptions7] = useState([
    { label: '', value: 0 },
    { label: '有効', value: 1 },
  ]);
  const [dropdownOptions8] = useState([
    { label: '', value: 0 },
    { label: '抽出名', value: 1 },
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[1]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[1]);
  const [selectedValue3, setSelectedValue3] = useState(dropdownOptions3[1]);
  const [selectedValue4, setSelectedValue4] = useState(dropdownOptions4[1]);
  const [selectedValue5, setSelectedValue5] = useState(dropdownOptions5[1]);
  const [selectedValue6, setSelectedValue6] = useState(dropdownOptions6[1]);
  const [selectedValue7, setSelectedValue7] = useState(dropdownOptions7[1]);
  const [selectedValue8, setSelectedValue8] = useState(dropdownOptions8[1]);

  if (!show) {
    return null;
  }

  return (
    <div className="fixed w-full h-full inset-0 flex justify-center bg-gray-800 bg-opacity-20 z-50 overflow-auto">
      <div className="w-full max-w-[716px] h-[644px] my-5 transform rounded bg-white px-10 py-5 text-left align-middle shadow-md transition-all">
          <div className="modal-header relative flex items-center justify-between">
            <p className="text-xl font-medium text-gray-900">印刷スケジュール</p>
            <button
              type="button"
              onClick={onClose}
              className="ml-auto inline-flex items-center rounded-lg bg-white text-gray-400 hover:text-gray-900 focus:outline-sky-600"
            >
              <span className="sr-only">Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="h-8 w-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                ></path>
              </svg>
            </button>
          </div>
          <div className="modal-body mt-5">
            <div className="relative">
              <table className="w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <tbody>
                  <tr className="bg-white hover:bg-gray-100 !h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      荷主名
                    </th>
                    <td className="w-[436px] border border-gray-200 px-2 py-1 text-sm-ch">
                      <CustomDropdown options={dropdownOptions1} selectedValue={selectedValue1}/>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 !h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                    スケジュール名称
                    </th>
                    <td className="w-[436px] border border-gray-200 px-2 py-1 text-sm-ch">
                        <div className="">
                        <input
                        className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                        placeholder="スケジュール名称"
                        type="text"
                        value=""
                      />
                        </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 !h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                    印刷時間
                    </th>
                    <td className="w-[436px] border border-gray-200 px-2 py-1 text-sm-ch flex gap-[9px]">
                        <div className="flex items-center">
                            <div className="w-[100px]">
                            <CustomDropdown options={dropdownOptions3} selectedValue={selectedValue3}/>
                            </div>
                            <p className="ml-2">時</p>
                        </div>
                        <div className="flex items-center">
                            <div className="w-[100px]">
                                <CustomDropdown options={dropdownOptions4} selectedValue={selectedValue4}/>
                            </div>
                            <p className="ml-2">分</p>
                        </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 !h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                    印刷種類
                    </th>
                    <td className="w-[436px] border border-gray-200 px-2 py-1 text-sm-ch flex items-center gap-[9px]">
                        <div className="w-[100px]">
                            <CustomDropdown options={dropdownOptions5} selectedValue={selectedValue5}/>
                        </div>
                        <p className="ml-4">※バッチ、抽出より選択</p>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 !h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                    抽出名
                    </th>
                    <td className="w-[436px] border border-gray-200 px-2 py-1 text-sm-ch">
                      <CustomDropdown options={dropdownOptions8} selectedValue={selectedValue8}/>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 !h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                    バッジ名
                    </th>
                    <td className="w-[436px] border border-gray-200 px-2 py-1 text-sm-ch">
                      <CustomDropdown options={dropdownOptions6} selectedValue={selectedValue6}/>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 !h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                    操業曜日
                    </th>
                    <td className="w-[436px] border border-gray-200 px-3 py-1 text-sm-ch">
                        <div className="flex items-center gap-[16px]">
                            <div className="flex items-center">
                                <input id="sort-radio" type="checkbox" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                                <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                                月
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input id="sort-radio" type="checkbox" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                                <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                                火
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input id="sort-radio" type="checkbox" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                                <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                                水
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input id="sort-radio" type="checkbox" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                                <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                                木
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input id="sort-radio" type="checkbox" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                                <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                                金
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input id="sort-radio" type="checkbox" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                                <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                                土
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input id="sort-radio" type="checkbox" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                                <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                                日
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input id="sort-radio" type="checkbox" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                                <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                                祝日
                                </label>
                            </div>
                        </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 !h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium" rowSpan="2">
                    休業日
                    </th>
                    <td className="w-[436px] border border-gray-200 px-3 py-1 text-sm-ch">
                       <button className="box-shadow-form h-[22px] min-w-[76px] rounded bg-sky-600 px-2 font-bold text-white focus:outline-sky-600">
                       カレンダー
                       </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-[436px] border border-gray-200 px-3 py-1 text-sm-ch h-[90px]">
                        <div className="flex items-center">
                            <input id="sort-radio" type="checkbox" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            本日
                            </label>
                        </div>
                        <div className="flex items-center mt-2">
                            <input id="sort-radio" type="checkbox" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            日時指定
                            </label>
                        </div>
                        <div className="flex gap-[12px] mt-2">
                            <button className="box-shadow-form h-[22px] min-w-[40px] rounded bg-[#E11D48] px-2 font-bold text-white focus:outline-sky-600">
                            削除
                            </button>
                            <button className="box-shadow-form h-[22px] min-w-[64px] rounded bg-sky-600 px-2 font-bold text-white focus:outline-sky-600">
                            標準保存
                            </button>
                            <button className="box-shadow-form h-[22px] min-w-[76px] rounded bg-sky-600 px-2 font-bold text-white focus:outline-sky-600">
                            標準コピー
                            </button>
                        </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 !h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                    有効/無効
                    </th>
                    <td className="w-[436px] border border-gray-200 px-2 py-1 text-sm-ch">
                       <div className="w-[100px]">
                            <CustomDropdown options={dropdownOptions7} selectedValue={selectedValue7}/>
                        </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer mt-5">
            <ul className="flex flex-wrap justify-center space-x-5">
              <li className="w-[80px] h-[34px]">
                <button className="box-shadow-form h-full w-full rounded bg-sky-600 px-3 font-medium text-white focus:outline-sky-600">
                登録
                </button>
              </li>
              <li className="w-[80px] h-[34px]">
                <button
                  type="button"
                  className="box-shadow-form h-full w-full font-medium rounded border border-gray-300 bg-white px-3 focus:outline-sky-600"
                >
                  閉じる
                </button>
              </li>
            </ul>
          </div>
      </div>
    </div>
  );
};
 
export default Modal;