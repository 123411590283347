import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";
import Modal from "../modal/Modal";
import Modal2 from "../modal/Modal2";
function Table() {
  const [dropdownOptions] = useState([
    { label: '処理を選択してください', value: '' },
    { label: '処理を選択してください', value: 1 },
    { label: '処理を選択してください', value: 2 },
    { label: '処理を選択してください', value: 3 },
    { label: '処理を選択してください', value: 4 },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal2 = () => {
    setShowModal2(true);
  };
  const handleCloseModal2 = () => {
    setShowModal2(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全5件のを表示しています </span>
                
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                  <button className="box-shadow-form h-9 min-w-20 rounded bg-emerald-700 px-3 py-2 font-bold text-white focus:outline-emerald-600" onClick={handleOpenModal}>新規マスター登録</button>
                  <button className="box-shadow-form h-9 min-w-20 rounded bg-emerald-700 px-3 py-2 font-bold text-white focus:outline-emerald-600" onClick={handleOpenModal2}>マスター登録</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr>
               
                    <th className="border bg-gray-100 px-2.5 py-2 w-[200px] align-top font-medium">
                    操作 
                    </th>
                    <th className="border bg-gray-100 px-2.5 w-[200px] py-2 align-top font-medium">
                    ID
                    </th>
                    <th className="border bg-gray-100 px-2.5 w-[700px] py-2 align-top font-medium">
                    ボックスコード 
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 align-top font-medium">
                    サイズ 
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white">
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <div className="flex">
                      <button class="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px] me-2">編集</button>
                      <button class="box-shadow-form h-6 rounded bg-rose-600 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">必須</button>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                   149 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                   Post 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    
                    </td>
                  </tr>
             
                  <tr className="bg-white">
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <div className="flex">
                      <button class="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px] me-2">編集</button>
                      <button class="box-shadow-form h-6 rounded bg-rose-600 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">必須</button>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                   150 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                   compact 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    
                    </td>
                  </tr>

                  <tr className="bg-white">
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <div className="flex">
                      <button class="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px] me-2">編集</button>
                      <button class="box-shadow-form h-6 rounded bg-rose-600 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">必須</button>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                   151
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                   bag-1 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    
                    </td>
                  </tr>

                  
                  <tr className="bg-white">
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <div className="flex">
                      <button class="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px] me-2">編集</button>
                      <button class="box-shadow-form h-6 rounded bg-rose-600 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">必須</button>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                   152
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                   bag-2
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    
                    </td>
                  </tr>

                  <tr className="bg-white">
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <div className="flex">
                      <button class="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px] me-2">編集</button>
                      <button class="box-shadow-form h-6 rounded bg-rose-600 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">必須</button>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                   153
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                   bag-3
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onClose={handleCloseModal} title="My Modal">
       </Modal>
       <Modal2 show={showModal2} onClose={handleCloseModal2} title="My Modal">
       </Modal2>
    </div>
  );
}

export default Table;
