import { useState } from "react";
import CustomPicker from "../../../components/layout/datepicker";
import CustomDropdown from "../../../components/layout/dropdown";
const Modal = ({ show, onClose, title, children }) => {
  const [dropdownOptions] = useState([
    { label: "削除", value: "" },
    { label: "処理を選択", value: 1 },
    { label: "返品入荷予定CSV出力", value: 2 },
    { label: "バーコード印刷", value: 3 },
    { label: "手動格納", value: 4 },
  ]);
  const [pageOptions] = useState([
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
  ]);
  const [dropdownOptions1] = useState([
    { label: "出荷停止", value: 0 },
    { label: "出荷停止", value: 1 },
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[1]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  if (!show) {
    return null;
  }

  return (
    <div className="fixed w-full h-full inset-0 flex justify-center bg-gray-800 bg-opacity-20 z-50 overflow-auto">
      <div className="w-full max-w-[753px] h-[1325px] my-5 transform rounded bg-white px-10 py-8 text-left align-middle shadow-md transition-all">
        <div className="modal-header relative flex items-center justify-between">
          <p className="text-xl font-medium text-gray-900">返品注文選択</p>
          <button
            type="button"
            onClick={onClose}
            className="ml-auto inline-flex items-center rounded-lg bg-white text-gray-400 hover:text-gray-900 focus:outline-sky-600"
          >
            <span className="sr-only">Close</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="h-8 w-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              ></path>
            </svg>
          </button>
        </div>
        <div className="modal-body mt-5">
          <div className="relative">
            <table className=" w-[504px] border border-gray-200 text-left text-gray-900 rtl:text-right">
              <tbody>
                <tr className="bg-white hover:bg-gray-100">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16 w-[160px] font-medium">
                    注文番号
                  </th>

                  <td className="w-[344px] border border-gray-200 px-3 h-16 w-[344px] py-3.5">
                    <input
                      className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                      placeholder=""
                      type="text"
                      value=""
                    />
                  </td>
                </tr>

                <tr className="bg-white hover:bg-gray-100">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16  w-[160px] font-medium">
                    <p className="m-0 d-flex">
                      <span className="my-auto me-2">注文日</span>
                    </p>
                  </th>
                  <td className="w-[344px] border-b  border-gray-200 px-3 h-16  py-3.5 flex">
                    <CustomPicker option={{ placeholder: "" }} />
                    <div className="flex items-center">
                      <span className="px-3 font-medium font-sans">~</span>
                    </div>
                    <CustomPicker option={{ placeholder: "" }} />
                  </td>
                </tr>

                <tr className="bg-white hover:bg-gray-100">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16  w-[160px] font-medium">
                    出荷日
                  </th>
                  <td className="w-[344px] border-gray-200 px-3 h-16  py-3.5 flex">
                    <CustomPicker option={{ placeholder: "" }} />
                    <div className="flex items-center">
                      <span className="px-3 font-medium font-sans">~</span>
                    </div>
                    <CustomPicker option={{ placeholder: "" }} />
                  </td>
                </tr>

                <tr className="bg-white hover:bg-gray-100">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16  w-[160px] font-medium">
                    <p className="m-0 d-flex">
                      <span className="my-auto me-2">送り状No.</span>
                    </p>
                  </th>
                  <td className="w-[344px] border border-gray-200 px-3 h-16 w-[344px] py-3.5">
                    <input
                      className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                      placeholder=""
                      type="text"
                      value=""
                    />
                  </td>
                </tr>

                <tr className="bg-white hover:bg-gray-100">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16  w-[160px] font-medium">
                    <p className="m-0 d-flex">
                      <span className="my-auto me-2">注文者名</span>
                    </p>
                  </th>
                  <td className="w-[344px] border border-gray-200 px-3 h-16 w-[344px] py-3.5">
                    <input
                      className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                      placeholder=""
                      type="text"
                      value=""
                    />
                  </td>
                </tr>

                <tr className="bg-white hover:bg-gray-100">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16  w-[160px] font-medium">
                    送付先名
                  </th>

                  <td className="w-[344px] border border-gray-200 px-3 h-16 w-[344px] py-3.5">
                    <input
                      className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                      placeholder=""
                      type="text"
                      value=""
                    />
                  </td>
                </tr>

                <tr className="bg-white hover:bg-gray-100">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16  w-[160px] font-medium">
                    注文番号
                  </th>
                  <td className="w-[344px] border border-gray-200 px-3 h-16 w-[344px] py-3.5">
                    <input
                      className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                      placeholder=""
                      type="text"
                      value=""
                    />
                  </td>
                </tr>

                <tr className="bg-white hover:bg-gray-100">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16  w-[160px] font-medium">
                    郵便番号
                  </th>
                  <td className="w-[344px] border border-gray-200 px-3 h-16 w-[344px] py-3.5">
                    <input
                      className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                      placeholder=""
                      type="text"
                      value=""
                    />
                  </td>
                </tr>

                <tr className="bg-white hover:bg-gray-100">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16  w-[160px] font-medium">
                    電話番号
                  </th>
                  <td className="w-[344px] border border-gray-200 px-3 h-16 w-[344px] py-3.5">
                    <input
                      className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                      placeholder=""
                      type="text"
                      value=""
                    />
                  </td>
                </tr>

                <tr className="bg-white hover:bg-gray-100">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16  w-[160px] font-medium">
                    品番
                  </th>
                  <td className="w-[344px] border border-gray-200 px-3 h-16 w-[344px] py-3.5">
                    <input
                      className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                      placeholder=""
                      type="text"
                      value=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ul className="flex flex-wrap  space-x-5 mt-5">
            <li className="w-[80px] h-[34px]">
              <button className="box-shadow-form h-full w-full rounded bg-sky-600 px-3 font-medium text-white focus:outline-sky-600">
                検索
              </button>
            </li>
          </ul>
          <div className="flex items-center justify-between mt-5">
            <div className="flex items-center">
              <span className="mr-5 inline-block">全2件</span>
              <div className="custom-dropdown relative inline-block min-w-[200px] text-left">
                <div>
                  <CustomDropdown
                    options={dropdownOptions}
                    selectedValue={selectedValue}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                  <CustomDropdown
                    options={pageOptions}
                    selectedValue={pageValue}
                  />
                </div>
                <nav className="box-shadow-form h-9">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right mt-4">
            <thead className="text-sm uppercase leading-custom font-medium">
              <tr className="border border-gray-200 bg-gray-100">
                <th
                  className="border bg-gray-100 px-2.5 py-3.5 w-[64px] align-top font-medium"
                  rowSpan="4"
                >
                  操作
                </th>
                <th
                  className="border bg-gray-100 w-[89px] align-top font-medium"
                  rowSpan="4"
                >
                  <p className="px-2.5 py-3.5 h-[46px] ">注文番号</p>

                  <p className="px-2.5 py-3.5 h-[46px] border-t">注文日</p>
                </th>
                <th
                  className="border bg-gray-100 px-2.5 py-3.5 w-[72px] align-top font-medium"
                  rowSpan="4"
                >
                  注文者名
                </th>
                <th
                  className="border bg-gray-100 px-2.5 py-3.5 w-[176px] align-top font-medium"
                  rowSpan="4"
                >
                  送付先住所
                </th>
                <th className="border bg-gray-100 px-2.5 py-3.5 w-[200px] align-top font-medium">
                  決済方法
                </th>
                <th
                  className="border bg-gray-100 px-2.5 py-3.5 w-[72px] align-top font-medium"
                  rowSpan="4"
                >
                  請求金額
                </th>
              </tr>

              <tr className="border border-gray-200 bg-gray-100">
                <th className="border px-2.5 py-3.5 align-top font-medium">
                  配送方法
                </th>
              </tr>

              <tr className="border border-gray-200 bg-gray-100">
                <th className="border px-2.5 py-3.5 align-top font-medium">
                  送り状No.（出荷）
                </th>
              </tr>

              <tr className="border border-gray-200 bg-gray-100">
                <th className="border px-2.5 py-3.5 align-top font-medium">
                  送り状No.（返品）
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border border-gray-200 ">
                <td
                  className="border px-2.5 py-3.5  align-top font-medium"
                  rowSpan="4"
                >
                  <button className="box-shadow-form h-6 rounded bg-sky-600 px-1 py-1 font-medium text-white focus:outline-sky-600 min-w-[40px]">
                    選択
                  </button>
                </td>

                <td className="border align-top font-medium" rowSpan="4">
                  <p className="px-2.5 py-3.5 h-[46px] ">12345</p>

                  <p className="px-2.5 py-3.5 h-[46px] border-t">2024/02/01</p>
                </td>
                <td
                  className="border  px-2.5 py-3.5  align-top font-medium"
                  rowSpan="4"
                >
                  伊藤太朗
                </td>
                <td
                  className="border  px-2.5 py-3.5  align-top font-medium"
                  rowSpan="4"
                >
                  東京都〇〇区〇〇町1-22-33
                </td>
                <td className="border  px-2.5 py-3.5  align-top font-medium">
                  クレジットカード
                </td>
                <td
                  className="border  px-2.5 py-3.5  align-top font-medium"
                  rowSpan="4"
                >
                  10,000
                </td>
              </tr>

              <tr className="border border-gray-200 ">
                <td className="border px-2.5 py-3.5 align-top font-medium">
                  ヤマト
                </td>
              </tr>

              <tr className="border border-gray-200">
                <td className="border px-2.5 py-3.5 align-top font-medium">
                  12345
                </td>
              </tr>

              <tr className="border border-gray-200 ">
                <td className="border px-2.5 py-3.5 align-top font-medium">
                  123456
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex items-center justify-end my-5">
            <div className="flex items-center justify-end">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                  <CustomDropdown
                    options={pageOptions}
                    selectedValue={pageValue}
                  />
                </div>
                <nav className="box-shadow-form h-9">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Modal;
