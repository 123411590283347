import React, { useState } from 'react'
import ProgressCategory from '../components/category/ProgressCategory'
import LayoutPM1 from '../mvp2/0625-21';

function ProgressRender() {
    const [show, setShow] = useState('layoutPM1');
    const handleDynamicLayout=(data)=>{
      setShow(data);
    };
  return (
    <>
    <ProgressCategory dynamicLayout={handleDynamicLayout}/>
    {show === 'layoutPM1' && <LayoutPM1 />}
    {/* {show === 'layoutPM2' && <Layout2 />}
    {show === 'layoutPM3' && <Layout3 />}
    {show === 'layoutPM4' && <Layout3 />} */}
   </>
  )
}

export default ProgressRender