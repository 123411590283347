import { useState } from "react";
import CustomPicker from "../../../components/layout/datepicker";
import CustomDropdown from "../../../components/layout/dropdown";
const Modal = ({ show, onClose, title, children }) => {
  const [dropdownOptions1] = useState([
    { label: '', value: 0 },
    { label: '配送会社選択', value: 1 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '', value: 0 },
    { label: '注文数', value: 1 },
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[1]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[1]);

  if (!show) {
    return null;
  }

  return (
    <div className="fixed w-full h-full inset-0 flex justify-center bg-gray-800 bg-opacity-20 z-50 overflow-auto">
      <div className="w-full max-w-[741px] h-[1618px] my-5 transform rounded bg-white px-[32px] py-5 text-left align-middle shadow-md transition-all">
          <div className="modal-header relative flex items-center justify-between">
            <button
              type="button"
              onClick={onClose}
              className="ml-auto inline-flex items-center rounded-lg bg-white text-gray-400 hover:text-gray-900 focus:outline-sky-600"
            >
              <span className="sr-only">Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="h-8 w-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                ></path>
              </svg>
            </button>
          </div>
          <div className="modal-body mt-2">
            <div className="relative">
              <div className="h-[38px] bg-[#0284C7] flex items-center mb-2">
                <p className="text-[20px] font-bold text-white mb-0 px-2">バッチ新規作成</p>
              </div>
              <table className="w-full border border-gray-200 text-left text-gray-900 rtl:text-right mb-4 table-fixed">
                <tbody>
                  <tr className="bg-white hover:bg-gray-100">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] h-[44px] font-medium">
                    バッチ名
                    </th>
                    <td className="w-[477px] border border-gray-200 px-2 py-1 h-[44px]">
                      <input
                          className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                          placeholder="バッジ名"
                          type="text"
                          value=""
                        />
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[60px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      説明
                    </th>
                    <td className="w-[477px] border border-gray-200 px-2 py-1">
                    <input
                        className="box-shadow-form h-[52px] w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                        placeholder="説明"
                        type="text"
                        value=""
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="h-[32px] bg-[#D1D5DB] flex items-center mb-2">
                <p className="text-[16px] font-bold text-[#111827] mb-0 px-2">バッチ基本設定</p>
                <p className="mb-0 text-[#E11D48] text-[12px] font-bold pl-1">※必須項目</p>
              </div>
              <table className="w-full border border-gray-200 text-left text-gray-900 rtl:text-right mb-4 table-fixed">
                <tbody>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>バッチ作成優先 <span className="text-[#E11D48]">※</span></p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-2 py-1 h-[44px]">
                           <div className="inline-flex items-center">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-10" defaultChecked className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            注文形態優先
                            </label>
                          </div>
                          <div className="inline-flex items-center ml-4">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-10" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            ロケ優先（ロボットロケ利用）
                            </label>
                          </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>バッチ種類</p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-2 py-1 h-[44px]">
                          <div className="flex items-center">
                            <p className="w-[66px]">1：シングル </p>
                            <div className="inline-flex items-center ml-3 w-[74px]">
                              <input id="sort-radio" type="radio" value="0" name="sort-radio-9" defaultChecked className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                              <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                              11バッチ
                              </label>
                            </div>
                            <div className="inline-flex items-center ml-4">
                              <input id="sort-radio" type="radio" value="0" name="sort-radio-9" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                              <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                              1バッチ
                              </label>
                            </div>
                          </div>
                          <div className="flex items-center mt-2">
                            <p className="w-[66px]">S：セイム</p>
                            <div className="inline-flex items-center ml-3 w-[74px]">
                              <input id="sort-radio" type="radio" value="0" name="sort-radio-7" defaultChecked className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                              <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                              Sバッチ
                              </label>
                            </div>
                            <div className="inline-flex items-center ml-4">
                              <input id="sort-radio" type="radio" value="0" name="sort-radio-7" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                              <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                              SGバッチ
                              </label>
                            </div>
                          </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>1バッジ定義</p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-2 py-1 h-[44px] flex items-center">
                      <div className="w-[60px]">
                        <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                              placeholder="注文数"
                              type="text"
                              value=""/>
                      </div>
                      <p className="mx-2">件以上を1バッジとする</p>
                      <svg width="17" height="17" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.375 9C0.375 4.51269 4.01269 0.875 8.5 0.875C12.9873 0.875 16.625 4.51269 16.625 9C16.625 13.4873 12.9873 17.125 8.5 17.125C4.01269 17.125 0.375 13.4873 0.375 9ZM9.85639 5.73606C9.11572 5.08798 7.88466 5.08798 7.14399 5.73606C6.88421 5.96337 6.48936 5.93704 6.26206 5.67727C6.03476 5.4175 6.06108 5.02264 6.32086 4.79534C7.53281 3.73489 9.46757 3.73489 10.6795 4.79534C11.9404 5.89862 11.9404 7.72638 10.6795 8.82966C10.4656 9.0168 10.2301 9.1701 9.98171 9.2903C9.41815 9.56304 9.12519 9.93515 9.12519 10.25V10.875C9.12519 11.2202 8.84537 11.5 8.50019 11.5C8.15501 11.5 7.87519 11.2202 7.87519 10.875V10.25C7.87519 9.18414 8.75792 8.49387 9.43718 8.16514C9.58938 8.09148 9.73042 7.99916 9.85639 7.88894C10.5481 7.28367 10.5481 6.34133 9.85639 5.73606ZM8.5 14C8.84518 14 9.125 13.7202 9.125 13.375C9.125 13.0298 8.84518 12.75 8.5 12.75C8.15482 12.75 7.875 13.0298 7.875 13.375C7.875 13.7202 8.15482 14 8.5 14Z" fill="#0284C7"/>
                        </svg>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>Sバッジ定義 </p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-2 py-1 h-[44px] flex items-center">
                        <div className="w-[60px]">
                          <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                                placeholder="注文数"
                                type="text"
                                value=""/>
                        </div>
                        <p className="mx-2">件以上をSバッチとする</p>
                        <svg width="17" height="17" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.375 9C0.375 4.51269 4.01269 0.875 8.5 0.875C12.9873 0.875 16.625 4.51269 16.625 9C16.625 13.4873 12.9873 17.125 8.5 17.125C4.01269 17.125 0.375 13.4873 0.375 9ZM9.85639 5.73606C9.11572 5.08798 7.88466 5.08798 7.14399 5.73606C6.88421 5.96337 6.48936 5.93704 6.26206 5.67727C6.03476 5.4175 6.06108 5.02264 6.32086 4.79534C7.53281 3.73489 9.46757 3.73489 10.6795 4.79534C11.9404 5.89862 11.9404 7.72638 10.6795 8.82966C10.4656 9.0168 10.2301 9.1701 9.98171 9.2903C9.41815 9.56304 9.12519 9.93515 9.12519 10.25V10.875C9.12519 11.2202 8.84537 11.5 8.50019 11.5C8.15501 11.5 7.87519 11.2202 7.87519 10.875V10.25C7.87519 9.18414 8.75792 8.49387 9.43718 8.16514C9.58938 8.09148 9.73042 7.99916 9.85639 7.88894C10.5481 7.28367 10.5481 6.34133 9.85639 5.73606ZM8.5 14C8.84518 14 9.125 13.7202 9.125 13.375C9.125 13.0298 8.84518 12.75 8.5 12.75C8.15482 12.75 7.875 13.0298 7.875 13.375C7.875 13.7202 8.15482 14 8.5 14Z" fill="#0284C7"/>
                        </svg>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>バッチ区注文切数</p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-2 py-1 h-[44px]">
                      <div className="flex items-center">
                          <p>1/1バッチ</p>
                          <div className="w-[60px] mx-2">
                            <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                                  placeholder="注文数"
                                  type="text"
                                  value=""/>
                          </div>
                          <p>件で１バッチを区切る
                          </p>
                      </div>
                      <div className="flex items-center my-2">
                          <p>1/Sバッチ</p>
                          <div className="w-[60px] mx-2">
                            <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                                  placeholder="注文数"
                                  type="text"
                                  value=""/>
                          </div>
                          <p>件で１バッチを区切る</p>
                      </div>
                      <div className="flex items-center">
                          <p>1/Dバッチ</p>
                          <div className="w-[60px] mx-2">
                            <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                                  placeholder="注文数"
                                  type="text"
                                  value=""/>
                          </div>
                          <p>件で１バッチを区切る</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="h-[32px] bg-[#D1D5DB] flex items-center mb-2">
                <p className="text-[16px] font-bold text-[#111827] mb-0 px-2">帳票設定</p>
                <p className="mb-0 text-[#E11D48] text-[12px] font-bold pl-1">※必須項目</p>
              </div>
              <table className="w-full border border-gray-200 text-left text-gray-900 rtl:text-right mb-4 table-fixed">
                <tbody>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>納品書分割</p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-3 py-1 h-[44px] flex">
                          <div className="inline-flex items-center">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-8" defaultChecked className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            分割する
                            </label>
                          </div>
                          <div className="inline-flex items-center ml-4">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-8" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            分割しない
                            </label>
                          </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>11バッチ納品書 印刷除外設定</p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-2 py-1 h-[44px] inline-flex items-center">
                      <div className="w-[274px]">
                        <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
                      </div>
                       <span className="text-[#E11D48] ml-1">※バッチごとに納品書を分割する</span>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>11バッチ納品書 送り状除外設定
                      </p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-2 py-1 h-[44px] inline-flex items-center">
                      <div className="w-[274px]">
                        <CustomDropdown options={dropdownOptions1} selectedValue={selectedValue1}  />
                      </div>
                        <span className="text-[#E11D48] ml-1">※納品書を後出しする場合に設定</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="h-[32px] bg-[#D1D5DB] flex items-center mb-2">
                <p className="text-[16px] font-bold text-[#111827] mb-0 px-2">バッチ内分割設定</p>
              </div>
              <table className="w-full border border-gray-200 text-left text-gray-900 rtl:text-right mb-4 table-fixed">
                <tbody>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>配送会社</p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-3 py-1 h-[44px] flex">
                         <div className="inline-flex items-center">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-2" defaultChecked className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            バッチ内分割しない
                            </label>
                          </div>
                          <div className="inline-flex items-center ml-4">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-2" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            バッチ内分割する
                            </label>
                          </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>代引き</p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-3 py-1 h-[44px] flex">
                          <div className="inline-flex items-center">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-3" defaultChecked className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            バッチ内分割しない
                            </label>
                          </div>
                          <div className="inline-flex items-center ml-4">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-3" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            バッチ内分割する
                            </label>
                          </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>のし、ラッピング
                      </p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-3 py-1 h-[44px] flex">
                          <div className="inline-flex items-center">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-4" defaultChecked className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            バッチ内分割しない
                            </label>
                          </div>
                          <div className="inline-flex items-center ml-4">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-4" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            バッチ内分割する
                            </label>
                          </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>倉庫備考</p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-3 py-1 h-[44px] flex">
                          <div className="inline-flex items-center">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-5" defaultChecked className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            バッチ内分割しない
                            </label>
                          </div>
                          <div className="inline-flex items-center ml-4">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-5" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            バッチ内分割する
                            </label>
                          </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>出荷予定日</p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-3 py-1 h-[44px] flex">
                          <div className="inline-flex items-center">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-6" defaultChecked className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            バッチ内分割しない
                            </label>
                          </div>
                          <div className="inline-flex items-center ml-4">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-6" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            バッチ内分割する
                            </label>
                          </div>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px]">
                    <th className="border bg-gray-100 px-3 text-sm-ch w-[200px] font-medium">
                      <p>B2B
                      </p>
                    </th>
                    <td className="w-[477px] border border-gray-200 px-3 py-1 h-[44px] flex">
                         <div className="inline-flex items-center">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-7" defaultChecked className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            Dバッチ除外しない
                            </label>
                          </div>
                          <div className="inline-flex items-center ml-4">
                            <input id="sort-radio" type="radio" value="0" name="sort-radio-7" className="h-4 w-4 border-gray-300 bg-white text-sky-600 focus:ring-sky-600" />
                            <label htmlFor="sort-radio" className="ms-2 font-medium text-gray-900">
                            Dバッチ除外するバッチに含まずオーダーピック
                            </label>
                          </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="h-[32px] bg-[#D1D5DB] flex items-center mb-2">
                <p className="text-[16px] font-bold text-[#111827] mb-0 px-2">送り状印刷順</p>
              </div>
              <table className="w-full border border-gray-200 text-left text-gray-900 rtl:text-right mb-4 table-fixed">
                <thead>
                  <tr className="h-[34px]">
                    <th className="w-[48px] text-center font-medium">
                      順番
                    </th>
                    <th className="h-[34px]  px-3 py-2 text-left font-medium">
                      配送会社
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white hover:bg-gray-100 h-[44px] border">
                    <td className="h-[44px] flex items-center justify-center">
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.30806 1.05806C4.55214 0.813981 4.94786 0.813981 5.19194 1.05806L8.94194 4.80806C9.18602 5.05214 9.18602 5.44786 8.94194 5.69194C8.69786 5.93602 8.30214 5.93602 8.05806 5.69194L5.375 3.00888V12.75C5.375 13.0952 5.09518 13.375 4.75 13.375C4.40482 13.375 4.125 13.0952 4.125 12.75V3.00888L1.44194 5.69194C1.19786 5.93602 0.802136 5.93602 0.558058 5.69194C0.313981 5.44786 0.313981 5.05214 0.558058 4.80806L4.30806 1.05806ZM12.25 4.625C12.5952 4.625 12.875 4.90482 12.875 5.25L12.875 14.9911L15.5581 12.3081C15.8021 12.064 16.1979 12.064 16.4419 12.3081C16.686 12.5521 16.686 12.9479 16.4419 13.1919L12.6919 16.9419C12.5747 17.0592 12.4158 17.125 12.25 17.125C12.0842 17.125 11.9253 17.0592 11.8081 16.9419L8.05806 13.1919C7.81398 12.9479 7.81398 12.5521 8.05806 12.3081C8.30214 12.064 8.69786 12.064 8.94194 12.3081L11.625 14.9911L11.625 5.25C11.625 4.90482 11.9048 4.625 12.25 4.625Z" fill="#0284C7"/>
                      </svg>
                    </td>
                    <td className="px-3 py-2.5">佐川急便
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px] border">
                    <td className="h-[44px] flex items-center justify-center">
                      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.30806 1.05806C4.55214 0.813981 4.94786 0.813981 5.19194 1.05806L8.94194 4.80806C9.18602 5.05214 9.18602 5.44786 8.94194 5.69194C8.69786 5.93602 8.30214 5.93602 8.05806 5.69194L5.375 3.00888V12.75C5.375 13.0952 5.09518 13.375 4.75 13.375C4.40482 13.375 4.125 13.0952 4.125 12.75V3.00888L1.44194 5.69194C1.19786 5.93602 0.802136 5.93602 0.558058 5.69194C0.313981 5.44786 0.313981 5.05214 0.558058 4.80806L4.30806 1.05806ZM12.25 4.625C12.5952 4.625 12.875 4.90482 12.875 5.25L12.875 14.9911L15.5581 12.3081C15.8021 12.064 16.1979 12.064 16.4419 12.3081C16.686 12.5521 16.686 12.9479 16.4419 13.1919L12.6919 16.9419C12.5747 17.0592 12.4158 17.125 12.25 17.125C12.0842 17.125 11.9253 17.0592 11.8081 16.9419L8.05806 13.1919C7.81398 12.9479 7.81398 12.5521 8.05806 12.3081C8.30214 12.064 8.69786 12.064 8.94194 12.3081L11.625 14.9911L11.625 5.25C11.625 4.90482 11.9048 4.625 12.25 4.625Z" fill="#0284C7"/>
                      </svg>
                    </td>
                    <td className="px-3 py-2.5">ヤマト運輸
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px] border">
                    <td className="h-[44px] flex items-center justify-center">
                      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.30806 1.05806C4.55214 0.813981 4.94786 0.813981 5.19194 1.05806L8.94194 4.80806C9.18602 5.05214 9.18602 5.44786 8.94194 5.69194C8.69786 5.93602 8.30214 5.93602 8.05806 5.69194L5.375 3.00888V12.75C5.375 13.0952 5.09518 13.375 4.75 13.375C4.40482 13.375 4.125 13.0952 4.125 12.75V3.00888L1.44194 5.69194C1.19786 5.93602 0.802136 5.93602 0.558058 5.69194C0.313981 5.44786 0.313981 5.05214 0.558058 4.80806L4.30806 1.05806ZM12.25 4.625C12.5952 4.625 12.875 4.90482 12.875 5.25L12.875 14.9911L15.5581 12.3081C15.8021 12.064 16.1979 12.064 16.4419 12.3081C16.686 12.5521 16.686 12.9479 16.4419 13.1919L12.6919 16.9419C12.5747 17.0592 12.4158 17.125 12.25 17.125C12.0842 17.125 11.9253 17.0592 11.8081 16.9419L8.05806 13.1919C7.81398 12.9479 7.81398 12.5521 8.05806 12.3081C8.30214 12.064 8.69786 12.064 8.94194 12.3081L11.625 14.9911L11.625 5.25C11.625 4.90482 11.9048 4.625 12.25 4.625Z" fill="#0284C7"/>
                      </svg>
                    </td>
                    <td className="px-3 py-2.5">日本郵便
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px] border">
                    <td className="h-[44px] flex items-center justify-center">
                     <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.30806 1.05806C4.55214 0.813981 4.94786 0.813981 5.19194 1.05806L8.94194 4.80806C9.18602 5.05214 9.18602 5.44786 8.94194 5.69194C8.69786 5.93602 8.30214 5.93602 8.05806 5.69194L5.375 3.00888V12.75C5.375 13.0952 5.09518 13.375 4.75 13.375C4.40482 13.375 4.125 13.0952 4.125 12.75V3.00888L1.44194 5.69194C1.19786 5.93602 0.802136 5.93602 0.558058 5.69194C0.313981 5.44786 0.313981 5.05214 0.558058 4.80806L4.30806 1.05806ZM12.25 4.625C12.5952 4.625 12.875 4.90482 12.875 5.25L12.875 14.9911L15.5581 12.3081C15.8021 12.064 16.1979 12.064 16.4419 12.3081C16.686 12.5521 16.686 12.9479 16.4419 13.1919L12.6919 16.9419C12.5747 17.0592 12.4158 17.125 12.25 17.125C12.0842 17.125 11.9253 17.0592 11.8081 16.9419L8.05806 13.1919C7.81398 12.9479 7.81398 12.5521 8.05806 12.3081C8.30214 12.064 8.69786 12.064 8.94194 12.3081L11.625 14.9911L11.625 5.25C11.625 4.90482 11.9048 4.625 12.25 4.625Z" fill="#0284C7"/>
                      </svg>
                    </td>
                    <td className="px-3 py-2.5">ゆうパケット
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px] border">
                    <td className="h-[44px] flex items-center justify-center">
                     <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.30806 1.05806C4.55214 0.813981 4.94786 0.813981 5.19194 1.05806L8.94194 4.80806C9.18602 5.05214 9.18602 5.44786 8.94194 5.69194C8.69786 5.93602 8.30214 5.93602 8.05806 5.69194L5.375 3.00888V12.75C5.375 13.0952 5.09518 13.375 4.75 13.375C4.40482 13.375 4.125 13.0952 4.125 12.75V3.00888L1.44194 5.69194C1.19786 5.93602 0.802136 5.93602 0.558058 5.69194C0.313981 5.44786 0.313981 5.05214 0.558058 4.80806L4.30806 1.05806ZM12.25 4.625C12.5952 4.625 12.875 4.90482 12.875 5.25L12.875 14.9911L15.5581 12.3081C15.8021 12.064 16.1979 12.064 16.4419 12.3081C16.686 12.5521 16.686 12.9479 16.4419 13.1919L12.6919 16.9419C12.5747 17.0592 12.4158 17.125 12.25 17.125C12.0842 17.125 11.9253 17.0592 11.8081 16.9419L8.05806 13.1919C7.81398 12.9479 7.81398 12.5521 8.05806 12.3081C8.30214 12.064 8.69786 12.064 8.94194 12.3081L11.625 14.9911L11.625 5.25C11.625 4.90482 11.9048 4.625 12.25 4.625Z" fill="#0284C7"/>
                      </svg>
                    </td>
                    <td className="px-3 py-2.5">ネコポス
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px] border">
                    <td className="h-[44px] flex items-center justify-center">
                     <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.30806 1.05806C4.55214 0.813981 4.94786 0.813981 5.19194 1.05806L8.94194 4.80806C9.18602 5.05214 9.18602 5.44786 8.94194 5.69194C8.69786 5.93602 8.30214 5.93602 8.05806 5.69194L5.375 3.00888V12.75C5.375 13.0952 5.09518 13.375 4.75 13.375C4.40482 13.375 4.125 13.0952 4.125 12.75V3.00888L1.44194 5.69194C1.19786 5.93602 0.802136 5.93602 0.558058 5.69194C0.313981 5.44786 0.313981 5.05214 0.558058 4.80806L4.30806 1.05806ZM12.25 4.625C12.5952 4.625 12.875 4.90482 12.875 5.25L12.875 14.9911L15.5581 12.3081C15.8021 12.064 16.1979 12.064 16.4419 12.3081C16.686 12.5521 16.686 12.9479 16.4419 13.1919L12.6919 16.9419C12.5747 17.0592 12.4158 17.125 12.25 17.125C12.0842 17.125 11.9253 17.0592 11.8081 16.9419L8.05806 13.1919C7.81398 12.9479 7.81398 12.5521 8.05806 12.3081C8.30214 12.064 8.69786 12.064 8.94194 12.3081L11.625 14.9911L11.625 5.25C11.625 4.90482 11.9048 4.625 12.25 4.625Z" fill="#0284C7"/>
                      </svg>
                    </td>
                    <td className="px-3 py-2.5">コンパクト
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px] border">
                    <td className="h-[44px] flex items-center justify-center">
                     <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.30806 1.05806C4.55214 0.813981 4.94786 0.813981 5.19194 1.05806L8.94194 4.80806C9.18602 5.05214 9.18602 5.44786 8.94194 5.69194C8.69786 5.93602 8.30214 5.93602 8.05806 5.69194L5.375 3.00888V12.75C5.375 13.0952 5.09518 13.375 4.75 13.375C4.40482 13.375 4.125 13.0952 4.125 12.75V3.00888L1.44194 5.69194C1.19786 5.93602 0.802136 5.93602 0.558058 5.69194C0.313981 5.44786 0.313981 5.05214 0.558058 4.80806L4.30806 1.05806ZM12.25 4.625C12.5952 4.625 12.875 4.90482 12.875 5.25L12.875 14.9911L15.5581 12.3081C15.8021 12.064 16.1979 12.064 16.4419 12.3081C16.686 12.5521 16.686 12.9479 16.4419 13.1919L12.6919 16.9419C12.5747 17.0592 12.4158 17.125 12.25 17.125C12.0842 17.125 11.9253 17.0592 11.8081 16.9419L8.05806 13.1919C7.81398 12.9479 7.81398 12.5521 8.05806 12.3081C8.30214 12.064 8.69786 12.064 8.94194 12.3081L11.625 14.9911L11.625 5.25C11.625 4.90482 11.9048 4.625 12.25 4.625Z" fill="#0284C7"/>
                      </svg>
                    </td>
                    <td className="px-3 py-2.5">西濃
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[44px] border">
                    <td className="h-[44px] flex items-center justify-center">
                      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.30806 1.05806C4.55214 0.813981 4.94786 0.813981 5.19194 1.05806L8.94194 4.80806C9.18602 5.05214 9.18602 5.44786 8.94194 5.69194C8.69786 5.93602 8.30214 5.93602 8.05806 5.69194L5.375 3.00888V12.75C5.375 13.0952 5.09518 13.375 4.75 13.375C4.40482 13.375 4.125 13.0952 4.125 12.75V3.00888L1.44194 5.69194C1.19786 5.93602 0.802136 5.93602 0.558058 5.69194C0.313981 5.44786 0.313981 5.05214 0.558058 4.80806L4.30806 1.05806ZM12.25 4.625C12.5952 4.625 12.875 4.90482 12.875 5.25L12.875 14.9911L15.5581 12.3081C15.8021 12.064 16.1979 12.064 16.4419 12.3081C16.686 12.5521 16.686 12.9479 16.4419 13.1919L12.6919 16.9419C12.5747 17.0592 12.4158 17.125 12.25 17.125C12.0842 17.125 11.9253 17.0592 11.8081 16.9419L8.05806 13.1919C7.81398 12.9479 7.81398 12.5521 8.05806 12.3081C8.30214 12.064 8.69786 12.064 8.94194 12.3081L11.625 14.9911L11.625 5.25C11.625 4.90482 11.9048 4.625 12.25 4.625Z" fill="#0284C7"/>
                      </svg>
                    </td>
                    <td className="px-3 py-2.5">福山
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer mt-5">
            <ul className="flex flex-wrap justify-center space-x-5">
              <li className="w-[80px] h-[34px]">
                <button className="box-shadow-form h-full w-full rounded bg-sky-600 px-3 font-medium text-white focus:outline-sky-600">
                登録
                </button>
              </li>
              <li className="w-[80px] h-[34px]">
                <button
                  type="button"
                  className="box-shadow-form h-full w-full font-medium rounded border border-gray-300 bg-white px-3 focus:outline-sky-600"
                >
                  閉じる
                </button>
              </li>
            </ul>
          </div>
      </div>
    </div>
  );
};
 
export default Modal;