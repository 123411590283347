import "../Header.css";
import { Link ,useLocation} from "react-router-dom";
import { useEffect, useState } from "react";

function SubmenuSecond({subheader2}) {
  const location = useLocation(); 
  const [url, setUrl] = useState(null);

  const changeLayout = (data) =>{
    setUrl(data.url);
  }
  useEffect(() => {
    let path = location.pathname.replace(/^\//, '');
    setUrl(path);
  }, [location]);

  return (
    <>
      <div className={`${subheader2.length === 0 ? 'hidden' : ''} top-header bg-sky-200 flex h-[38px] px-10`}>
        <div className="top-header-menu-left bottom-menu flex h-full gap-4">
          <div className="top-header-menu-list h-full">
            <ul className="list-none flex h-full items-center">
              {
               subheader2.length > 0 
               ? subheader2.map((item,index)=> <li key={index} className={`${url?.includes(item?.url) ? "active" : ''} hover:bg-[#09A1EC] hover:font-bold focus:outline-sky-600 h-full flex items-center`} onClick={()=>changeLayout(item)}><Link to={item.url} className="px-4 block font-bold">{item.name}</Link></li>)
               : null
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubmenuSecond;
