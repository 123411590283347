import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";


function Table() {
  const [dropdownOptions] = useState([
    { label: '処理を選択してください', value: '' },
    { label: '処理を選択してください', value: 1 },
    { label: '処理を選択してください', value: 2 },
    { label: '処理を選択してください', value: 3 },
    { label: '処理を選択してください', value: 4 },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全12件</span>
                  <div
                    className="custom-dropdown relative inline-block min-w-[200px] text-left" >
                    <div>
                      <CustomDropdown options={dropdownOptions} selectedValue={selectedValue}/>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[48px] font-medium">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="checkbox_all" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[100px] align-top font-medium">
                    <div class="flex items-center">
                    得意先 ID
                    {/* <button class="text-sky-600 underline focus:outline-sky-600">得意先 ID</button> */}
                      <p class="icon-sort inline-block ms-auto"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" class="h-4 w-4 text-gray-400"><path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd"></path></svg></p>
                      </div>
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[120px] align-top font-medium">
                    得意先コード 
                    </th>
                    <th
                     className="border bg-gray-100 px-2.5 py-2  w-[200px] align-top font-medium"
                    >
                   得意先名
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[120px] align-top font-medium"
                    >
                      郵便番号
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[150px] align-top font-medium"
                    >
                     住所
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[100px] align-top font-medium"
                    >
                      FAX番号
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[72px] align-top font-medium"
                    >
                     メール
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[120px] align-top font-medium"
                    >
                   電話番号 
                    </th>
                   
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white">
                
                    <td className="border border-gray-200 px-2.5 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <button className="text-sky-600 underline focus:outline-sky-600">
                        1
                      </button>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                      AMA 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      AMAZON 
                    </td>
                    <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                     100-0001 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    東京 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    090 
                    </td>
                  </tr>
               
                  <tr className="bg-white">
                <td className="border border-gray-200 px-2.5 py-2">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                    />
                    <label htmlFor="1" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  <button className="text-sky-600 underline focus:outline-sky-600">
                    68483
                  </button>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                  tokui1
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  テスト得意先1 
                </td>
                <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                106-0041
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                東京都港区麻布台1-1-1
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                03-1234-5678 
                </td>
              </tr>

              <tr className="bg-white">
                <td className="border border-gray-200 px-2.5 py-2">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                    />
                    <label htmlFor="1" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  <button className="text-sky-600 underline focus:outline-sky-600">
                    68484
                  </button>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                  tokui2
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  テスト得意先2
                </td>
                <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                106-0041
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                東京都港区麻布台1-1-1
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                03-1234-5678 
                </td>
              </tr>

              <tr className="bg-white">
                <td className="border border-gray-200 px-2.5 py-2">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                    />
                    <label htmlFor="1" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  <button className="text-sky-600 underline focus:outline-sky-600">
                    68485
                  </button>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                  tokui3
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  テスト得意先3X
                </td>
                <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                106-0041
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                東京都港区麻布台1-1-1
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                03-1234-5678 
                </td>
              </tr>

              <tr className="bg-white">
                <td className="border border-gray-200 px-2.5 py-2">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                    />
                    <label htmlFor="1" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  <button className="text-sky-600 underline focus:outline-sky-600">
                    68486
                  </button>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                  tokui4
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  テスト得意先4 送付先名2★
                </td>
                <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                106-0041
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                東京都港区麻布台★1-1-1
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                03-1234-5678 
                </td>
              </tr>


              <tr className="bg-white">
                <td className="border border-gray-200 px-2.5 py-2">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                    />
                    <label htmlFor="1" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  <button className="text-sky-600 underline focus:outline-sky-600">
                    112490
                  </button>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                私は、責任感を···
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                私は、責任感を大事にしています。・・・
                </td>
                <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                ああああああああ
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                巣鴨駅はJR山手
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                線と三田線が・・・
                </td>
              </tr>

              <tr className="bg-white">
                <td className="border border-gray-200 px-2.5 py-2">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                    />
                    <label htmlFor="1" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  <button className="text-sky-600 underline focus:outline-sky-600">
                    112534
                  </button>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                shop-A
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                店舗A 
                </td>
                <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                1050021 
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                東京都港区東新橋 
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                00000000000 
                </td>
              </tr>

              <tr className="bg-white">
                <td className="border border-gray-200 px-2.5 py-2">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                    />
                    <label htmlFor="1" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  <button className="text-sky-600 underline focus:outline-sky-600">
                    112535
                  </button>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                central-warehouse 
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                セントラル倉庫 
                </td>
                <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                1050021 
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                東京都港区東新橋 
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                00000000000 
                </td>
              </tr>

              <tr className="bg-white">
                <td className="border border-gray-200 px-2.5 py-2">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                    />
                    <label htmlFor="1" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  <button className="text-sky-600 underline focus:outline-sky-600">
                    112536
                  </button>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                shop-B 
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                店舗 B
                </td>
                <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                1050021 
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                東京都港区東新橋 
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                00000000000 
                </td>
              </tr>

              <tr className="bg-white">
                <td className="border border-gray-200 px-2.5 py-2">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                    />
                    <label htmlFor="1" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  <button className="text-sky-600 underline focus:outline-sky-600">
                    119778
                  </button>
                </td>
                <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                1
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                得意先登録0712 得意先登録2 
                </td>
                <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                1050021 
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                東京都港区東新橋2-11-4 
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                  
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                
                </td>
                <td className="border border-gray-200 px-2.5 py-2 align-top">
                090090090
                </td>
              </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  );
}

export default Table;
