//sub headers
export const initialSubheader = [
  { id: 1, name: '出荷管理', active: false, url: 'business/shipping-management' },
  { id: 2, name: '移動管理', active: false, url: 'business/under-process'},
  {
    id: 3, name: '入荷管理', active: true, url: 'business/arrival-management',
    submenu: [
      { id: 31, name: '通常出荷', active: false, url: 'business/arrival-management/under-process' },
      { id: 32, name: '返品出荷', active: false, url: 'business/arrival-management/return-shipment' },
      { id: 33, name: 'メーカー返品', active: false, url: 'business/arrival-management/under-process' },
    ]
  },
  {
    id: 4, name: '返品管理', active: false, url: 'business/return-management',
    submenu: [
      { id: 41, name: '返品管理', active: false, url: 'business/return-management/return-manage' },
      { id: 42, name: 'メーカー返品', active: false, url: 'business/return-management/under-process' },
    ]
  },
  {
    id: 5, name: 'セット管理', active: false, url: 'business/set-management',
    submenu: [
      { id: 21, name: 'セット商品マスタ', active: false, url: 'business/set-management/set-master' },
      { id: 22, name: 'セット組制作', active: false, url: 'business/set-management/set-product' },
      { id: 23, name: 'セットバラシ', active: false, url: 'business/set-management/set-disassembly' },
    ]
  },
  { id: 6, name: '加工管理', active: false,url: 'business/under-process' },
  { id: 7, name: '棚卸管理', active: false, url: 'business/under-process' },
];
export const initialSubheader2 = [
  { id: 1, name: '進捗管理', active: false,url: 'warehouse/progress-management', 
  },
  { id: 2, name: '印刷一覧', active: false, url: 'warehouse/print-list'
  },
  {
    id: 3, name: '印刷スケジュール', active: true, url: 'warehouse/print-schedule'
  },
  {
    id: 4, name: 'バッチルール', active: false, url: 'warehouse/batch-rules'
  },
  {
    id: 5, name: 'エラーログ', active: false, url: 'warehouse/error-log'
  },
  { 
    id: 6, name: '操作ログ', active: false, url :'warehouse/operation-log'

  },
];
export const initialSubheader6 = [
  { id: 1, name: '倉庫オーナー', active: false,url: 'system/under-process', 
  },
  { id: 2, name: '倉庫管理', active: false, url: 'system/under-process'
  },
  {
    id: 3, name: '荷主管理', active: true, url: 'system/under-process'
  },
  {
    id: 4, name: 'ユーザー管理', active: false, url: 'system/under-process'
  },
  {
    id: 5, name: 'マスタ管理', active: false, url: 'system/master',
    submenu: [
      { id: 61, name: '得意先', active: false, url: 'system/master/customer' },
      { id: 62, name: '発送元', active: false, url: 'system/master/ships-form' },
      { id: 63, name: '箱マスタ', active: false, url: 'system/master/box-master' },
      { id: 64, name: '配送方法', active: false, url: 'system/master/delivery-method' },
      { id: 65, name: '配送時間', active: false, url: 'system/master/delivery-time' },
      { id: 66, name: '支払方法', active: false, url: 'system/master/payment-method' },
      { id: 67, name: '納品書設定', active: false, url: 'system/master/delivery-node-settings' },
    ]
  },
  { 
    id: 6, name: '印刷設定', active: false, url :'system/under-process',
  },
];
export const initialSubheader4 = [
  { id: 41, name: '在庫管理', active: false,url: 'inventory-management/under-process', 
  },
  { id: 42, name: '在庫履歴', active: false, url: 'inventory-management/under-process'
  },
  {
    id: 43, name: '受払い', active: true, url: 'inventory-management/under-process'
  },
  {
    id: 44, name: '月末在庫', active: false, url: 'inventory-management/under-process'
  },
  {
    id: 45, name: '設定', active: false, url: 'inventory-management/setting',
    submenu: [
      { id: 451, name: 'ロケマスタ', active: false, url: 'inventory-management/setting/location-master' },
      { id: 452, name: 'エリア・ゾーンマスタ', active: false, url: 'inventory-management/setting/area-master' },
      { id: 453, name: '商品区分', active: false, url: 'inventory-management/setting/product-classification' },
      { id: 454, name: '在庫区分', active: false, url: 'inventory-management/setting/inventory-classification' },
      { id: 455, name: 'ロケレベル', active: false, url: 'inventory-management/setting/location-level' },
    ]
  }
];
//Header
export const mainHeader = [
  { id: 'M1', name: '倉庫業務', active: false, url : 'warehouse', mainSubmenu :[initialSubheader2] 

  },
  { id: 'M2', name: '業務管理', active: false, url: 'business', mainSubmenu :[initialSubheader]

  },
  {
    id: 'M3', name: '商品マスタ', active: true, url: 'product', mainSubmenu :[]
  },
  {
    id: 'M4', name: '在庫管理', active: false, url: 'inventory-management', mainSubmenu :[initialSubheader4]
  },
  {
    id: 'M5', name: '入出力', active: false, url: 'input-output', mainSubmenu :[]
  },
  { id: 'M6', name: 'システム管理', active: false, url : 'system', mainSubmenu :[initialSubheader6] 
  },
];


