import React from 'react'
import Table from './table/Table'
// import Filter from './filter/index';

function LayoutNew3() {
  return (
    <>
    {/* <Filter /> */}
    <Table />
    </>
  )
}

export default LayoutNew3