import { useState } from "react";
import CustomPicker from "../../../components/layout/datepicker";
import CustomDropdown from "../../../components/layout/dropdown";

function Filter() {
  const [dropdownOptions1] = useState([
    { label: '', value: 0 },
    { label: 'その他検索項目', value: 1 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '在庫区分', value: 0 },
    { label: '在庫区分', value: 1 }
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[0]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[0]);
  const handleSelectChange = (newValue, setter) => {
    setter(newValue)
  }
  return (
    <div>
      <div className="px-10">
        <div className="py-4">
        {selectedValue1.value == 0 && (
        <div className="form-group -mx-5 -mb-4 flex flex-wrap">
              <div className="mb-4 px-5">
                <div className="flex items-center">
                  <div className="w-[118.5px]">
                    <CustomPicker option={{ placeholder: '返品登録日' }}/>
                  </div>
                  <span className="px-3 font-medium font-sans">~</span>
                  <div className="w-[118.5px]">
                    <CustomPicker option={{ placeholder: '返品登録日' }} />
                  </div>
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="flex items-center">
                  <div className="w-[118.5px]">
                    <CustomPicker option={{ placeholder: '入荷実績日' }} />
                  </div>
                  <span className="px-3 font-medium font-sans">~</span>
                  <div className="w-[118.5px]">
                    <CustomPicker option={{ placeholder: '入荷実績日' }} />
                  </div>
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[270px]">
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="品番または品名、バーコード" type="text" />
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[270px]">
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="作業者" type="text" />
                </div>
              </div>
        
            </div>
              )}
            {selectedValue1.value == 1 && (
            <div className="form-group -mx-5 -mb-4 flex flex-wrap mt-4">
                  <div className="mb-4 px-5">
                    <div className="flex items-center">
                      <div className="w-[170px]">
                        <CustomPicker option={{ placeholder: '返品登録日' }}/>
                      </div>
                      <span className="px-3 font-medium font-sans">~</span>
                      <div className="w-[170px]">
                        <CustomPicker option={{ placeholder: '返品登録日' }} />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="flex items-center">
                      <div className="w-[170px]">
                        <CustomPicker option={{ placeholder: '入荷実績日' }} />
                      </div>
                      <span className="px-3 font-medium font-sans">~</span>
                      <div className="w-[170px]">
                        <CustomPicker option={{ placeholder: '入荷実績日' }} />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="w-[373px]">
                      <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="品番または品名、バーコード" type="text" />
                    </div>
                  </div>
              
                </div>
             )}
            <div className="form-group -mx-5 -mb-4 flex flex-wrap mt-4">
            <div className="mb-4 flex px-5">
                <div className="w-[134px] h-[36px] dropdown-ch">
                  <CustomDropdown options={dropdownOptions1} selectedValue={selectedValue1} onChange={(newVal) => handleSelectChange(newVal,setSelectedValue1)} />
                </div>
                <div className="pl-10">
                  <button className="box-shadow-form h-9 min-w-20 rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                  検索
                  </button>
                </div>
              </div>
              </div>

            {selectedValue1.value == 1 && (
              <div>
             
                <div className="form-group -mx-5 -mb-4 flex flex-wrap mt-4">
                  <div className="mb-4 px-5">
                    <div className="flex items-center">
                      <div className="w-[170px]">
                        <CustomPicker option={{ placeholder: '返品登録日' }}/>
                      </div>
                      <span className="px-3 font-medium font-sans">~</span>
                      <div className="w-[170px]">
                        <CustomPicker option={{ placeholder: '返品登録日' }} />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="flex items-center">
                      <div className="w-[170px]">
                        <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="入荷残数" type="text" />
                      </div>
                      <span className="px-3 font-medium font-sans">~</span>
                      <div className="w-[170px]">
                        <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="入荷残数" type="text" />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="w-[373px]">
                      <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="w-[373px]">
                      <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="商品区分" type="text" />
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="w-[373px]">
                      <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="ロット" type="text" />
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="w-[373px]">
                      <CustomPicker option={{ placeholder: '消費期限' }}/>
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="w-[373px]">
                      <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="注文番号" type="text" />
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="flex items-center">
                      <div className="w-[170px]">
                        <CustomPicker option={{ placeholder: '出荷日(元注文)' }}/>
                      </div>
                      <span className="px-3 font-medium font-sans">~</span>
                      <div className="w-[170px]">
                        <CustomPicker option={{ placeholder: '出荷日（元注文）' }} />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="w-[373px]">
                      <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="仕入先" type="text" />
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="w-[373px]">
                      <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="備考" type="text" />
                    </div>
                  </div>
                  <div className="mb-4 px-5">
                    <div className="w-[373px]">
                      <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="作業者" type="text" />
                    </div>
                  </div>
                </div>
              </div>
            )}
           

        </div>
        
      </div>
    </div>
  );
}

export default Filter;
