import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";
import Modal from "../modal/Modal";

function Table() {
  const [dropdownOptions] = useState([
    { label: '入庫取消', value: '' },
    { label: '処理を選択', value: 1 },
    { label: '入庫済CSV出力', value: 2 },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全3件</span>
                  <div
                    className="custom-dropdown relative inline-block min-w-[200px] text-left" >
                    <div>
                      <CustomDropdown options={dropdownOptions} selectedValue={selectedValue}/>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[48px] font-medium">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="checkbox_all" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[48px] align-top font-medium">
                      入荷
                      <br />
                      種別
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[96px] align-top font-medium">
                    返品実績日時
                    </th>
                    <th
                     className="border bg-gray-100 px-2.5 py-2  w-[328px] align-top font-medium"
                    >
                      品番
                      <br />
                      バーコード
                      <br />
                      商品名
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[60px] align-top font-medium"
                    >
                     返品数
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[72px] align-top font-medium"
                    >
                     商品区分
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[72px] align-top font-medium"
                    >
                     在庫区分
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[60px] align-top font-medium"
                    >
                     ロット
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[72px] align-top font-medium"
                    >
                    消費期限
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[96px] align-top font-medium"
                    >
                     ロケーション
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[72px] align-top font-medium"
                    >
                    注文番号
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[60px] align-top font-medium"
                    >
                      返品元
                    </th>
                    <th
                     
                     className="border bg-gray-100 px-2.5 py-2 w-[60px] align-top font-medium"
                   >
                   仕入先
                   </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[96px] align-top font-medium"
                    >
                    作業者
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-2.5 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                       返品
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <p>2024/02/03</p>
                      <p>09:52:30</p>
                    </td>
                    <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                      <p>TCT001</p>
                      <p>4912345123459</p>
                      <p>商品AAA</p>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                      <p>5</p>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    返品
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    伊藤たかひろ
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-2.5 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                      返品
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    <p>2024/02/03</p>
                      <p>09:52:30</p>
                    </td>
                    <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                      <p>TCT002</p>
                      <p>4912345123460</p>
                      <p>商品BBB</p>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                      <p>3</p>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    良品
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"> </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"> </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    伊藤太朗
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-2.5 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                      返品
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    <p>2024/02/03</p>
                      <p>09:52:30</p>
                    </td>
                    <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                      <p>TCT003</p>
                      <p>4912345123461</p>
                      <p>商品CCC</p>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                      <p>2</p>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    良品
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    伊藤太朗
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onClose={handleCloseModal} title="My Modal">
       </Modal>
    </div>
  );
}

export default Table;
