import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";

function Table() {
  const [dropdownOptions] = useState([
    { label: '削除', value: '' },
    { label: '処理を選択', value: 1 },
    { label: '返品入荷予定CSV出力', value: 2 },
    { label: 'バーコード印刷', value: 3 },
    { label: '手動格納', value: 4 },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全2件</span>
                </div>
                <div className="flex items-center justify-end">
                  <div className="mr-5">
                  <button className="box-shadow-form border h-9 min-w-20 rounded bg-white px-3 py-2 text-[#111827] focus:outline-sky-600">
                  エラーCode一覧
                  </button>
                  </div>
                  <div className="flex items-center justify-end gap-5">
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr className="h-[34px]">
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[292px] align-top font-medium">
                      <p>荷主(ID)</p>
                      <p className="mt-1">スケジュール名称</p>
                    </th>
                    <th className="break-all border bg-gray-100 px-3 py-2  w-[292px] align-top font-medium">
                      <p>印刷</p>
                      <p className="mt-1">種類</p>
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[72px] align-top font-medium">
                    回数
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[96px] align-top font-medium">
                      <p>注文数</p>
                      <p className="text-[#E11D48]">(エラー数)</p>
                    </th>
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[292px] align-top font-medium">
                      <p>作成日</p>
                      <p className="my-1">開始時間</p>
                      <p>終了時間</p>
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[292px] align-top font-medium">
                    ステータス
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[72px] align-top font-medium">
                    注文番号
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[300px] align-top font-medium">
                    エラーコード
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="break-all border border-gray-200 px-3 py-2 text-left align-top">
                     <p className="text-sky-600 focus:ring-sky-600 cursor-pointer underline">240501-1002-2</p>
                     <p className="my-1">ABC吉店(1002)</p>
                     <p>午前2回目</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    バッチ
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                   2
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    200
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>2024/05/01</p>
                      <p className="my-1">11:00:01</p>
                      <p>11:02:15</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    データ作成済
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top  text-sky-600 focus:ring-sky-600 cursor-pointer underline">
                    <p>11111</p>
                    <p className="my-1">22222</p>
                    <p>33333</p>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                     <p>2(印刷先プリンターが設定されていません)</p>
                     <p className="my-1">2(印刷先プリンターが設定されていません)</p>
                     <p>3(後払い印字データがありません)</p>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                     <p className="text-sky-600 focus:ring-sky-600 cursor-pointer underline">240501-1002-1</p>
                     <p className="my-1">ABC告店(1002)</p>
                     <p>朝一作業分</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    バッチ
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    1
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    100
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>2024/05/01</p>
                      <p className="my-1">09:00:01</p>
                      <p>09:01:30</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    データ作成済
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top text-sky-600 focus:ring-sky-600 cursor-pointer underline">
                    44444
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                     <p> 2(印刷先プリンターが設定されていません)</p>
                     <p className="my-1">2(印刷先プリンターが設定されていません)</p>
                     <p>3(後払い印字データがありません)</p>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                     <p className="text-sky-600 focus:ring-sky-600 cursor-pointer underline">240501-1002-1</p>
                     <p className="my-1">BBB雜貨(1003)</p>
                     <p>配送指定日優先</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    抽出
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    1
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    50
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>2024/05/01</p>
                      <p className="my-1">09:00:01</p>
                      <p>09:01:30</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    印刷待ち
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top  text-sky-600 focus:ring-sky-600 cursor-pointer underline">
                    5555
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    1(荷主設定で納品書選択がされていません)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        
    </div>
  );
}

export default Table;
