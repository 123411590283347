import { useState } from "react";
import CustomPicker from "../../../components/layout/datepicker";
import CustomDropdown from "../../../components/layout/dropdown";
const Modal = ({ show, onClose, title, children }) => {
  
  const [dropdownOptions1] = useState([
    { label: 'テスト1', value: 0 },
    { label: 'テスト1', value: 1 },
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[1]);
  if (!show) {
    return null;
  }

  return (
    <div className="fixed w-full h-full inset-0 flex justify-center bg-gray-800 bg-opacity-20 z-50 overflow-auto">
      <div className="w-full max-w-[750px] h-[1135px] my-5 transform rounded bg-white p-3 text-left align-middle shadow-md transition-all">
          <div className="modal-header relative flex items-center justify-between">
            <p className="text-xl font-medium text-gray-900">発送元</p>
            <button
              type="button"
              onClick={onClose}
              className="ml-auto inline-flex items-center rounded-lg bg-white text-gray-400 hover:text-gray-900 focus:outline-sky-600"
            >
              <span className="sr-only">Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="h-8 w-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                ></path>
              </svg>
            </button>
          </div>
          <div className="modal-body mt-0">
            <div className="relative">
            <div className="my-3">
  
        <div className="pt-4 border">
          
            <div className="form-group flex flex-wrap">
              <div className="mb-4 px-5">
                <div className="w-[198px]">
                <p className="mb-2">発送元ID</p>
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="3" type="text" />
                </div>
              </div>
           
            </div>
     
        </div>
        
          </div>

          <div className="mb-3">
  
  <div className="pt-4 border">
    
      <div className="form-group flex-wrap">
        <div className="mb-4 px-5">
          <div className="w-[198px]">
          <p className="mb-2">発送元名</p>
      
            <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="店舗" type="text" />
          </div>
        </div>
        <div className="mb-4 px-5">
          <div className="w-[198px]">
          <p className="mb-2">郵便番号</p>
          <div className="flex">
          <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600 rounded-tr-none rounded-br-none" placeholder="106-0041" type="text" />
          <input className="box-shadow-form h-9 w-full rounded border border-gray-300 w-[50px] px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600 border-s-0 rounded-tl-none rounded-bl-none" placeholder="検索" type="text" />
            </div>
         
          </div>
        </div>
        <div className="mb-4 px-5">
          <div className="w-full">
          <p className="mb-2">住所</p>
            <textarea className="box-shadow-form h-[80px] w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="麻布台1-9-10 1111" type="text"></textarea>
          </div>
        </div>
      </div>

      <div className="form-group flex flex-wrap">
        <div className="mb-4 px-5">
          <div className="w-[198px]">
          <p className="mb-2">電話番号</p>
            <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="03344311337" type="text" />
          </div>
        </div>
        <div className="mb-4 px-5">
          <div className="w-[198px]">
          <p className="mb-2">メール</p>
            <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="メール" type="text" />
          </div>
        </div>
        <div className="mb-4 px-5">
          <div className="w-[198px]">
          <p className="mb-2">URL</p>
            <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="URL" type="text" />
          </div>
        </div>
      </div>
  </div>
  
    </div>

    <div className="mb-3">
  
  <div className="pt-4 border">
    
      <div className="form-group flex flex-wrap">
        <div className="mb-4 px-5">
          <div className="w-[198px]">
          <p className="mb-2">納品書設定</p>
          <CustomDropdown options={dropdownOptions1} selectedValue={selectedValue1}/>
          </div>
        </div>
     
      </div>

  </div>
  
    </div>
    <div className="mb-3">
  
  <div className="pt-4 border">
    
      <div className="form-group flex-wrap">
       
        <div className="mb-4 px-5">
          <div className="w-full">
          <p className="mb-2">納品書備考1</p>
            <textarea className="box-shadow-form h-[80px] w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="納品書備考1" type="text"></textarea>
          </div>
        </div>
        <div className="mb-4 px-5">
          <div className="w-full">
          <p className="mb-2">納品書備考2</p>
            <textarea className="box-shadow-form h-[80px] w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="納品書備考2" type="text"></textarea>
          </div>
        </div>
        <div className="mb-4 px-5">
          <div className="w-full">
          <p className="mb-2">納品書備考3</p>
            <textarea className="box-shadow-form h-[80px] w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="納品書備考3" type="text"></textarea>
          </div>
        </div>
      </div>

      
  </div>
  
    </div>

            </div>
          </div>
          <div className="modal-footer mt-5">
            <ul className="flex flex-wrap justify-center space-x-5">
              {/* <li className="w-[80px] h-[34px]">
                <button className="box-shadow-form h-full w-full rounded bg-sky-600 px-3 font-medium text-white focus:outline-sky-600">
                登録
                </button>
              </li> */}
              <li className="w-[80px] h-[34px]">
                <button
                  type="button"
                  className="box-shadow-form h-full w-full font-medium rounded border border-gray-300 bg-white px-3 focus:outline-sky-600"
                >
                  閉じる
                </button>
              </li>
            </ul>
          </div>
      </div>
    </div>
  );
};
 
export default Modal;