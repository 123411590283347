import React, { useState } from 'react'

function MobileCategory({dynamicLayout}) {
    const [activeLayout,setActiveLayout] = useState('layoutm2');
    const showLayouts=(data)=>{
        dynamicLayout(data);
        setActiveLayout(data);
    }
  return (
    <>
     <div>
     
      <div className="px-10 pt-4 category-list-hide">
         <div className='bg-[#FEF9C3] h-[52px] mb-4 flex items-center px-4 justify-between'>
          <div className='flex gap-[12px]'>
            <div>
                <svg width="16" height="16" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M5.26749 1.00226C6.03715 -0.331809 7.96257 -0.33181 8.73223 1.00226L13.6351 9.5006C14.4043 10.8339 13.4421 12.5 11.9027 12.5H2.09698C0.55766 12.5 -0.404626 10.8339 0.364605 9.5006L5.26749 1.00226ZM7 4.5C7.27614 4.5 7.5 4.72386 7.5 5V7.5C7.5 7.77614 7.27614 8 7 8C6.72386 8 6.5 7.77614 6.5 7.5V5C6.5 4.72386 6.72386 4.5 7 4.5ZM7 10C7.27614 10 7.5 9.77614 7.5 9.5C7.5 9.22386 7.27614 9 7 9C6.72386 9 6.5 9.22386 6.5 9.5C6.5 9.77614 6.72386 10 7 10Z" fill="#FACC15"/>
                </svg>
            </div>
            <div className='flex'>
              <p>印刷中にエラーが1件発生しました。</p>
              <p className='text-sky-600 underline'> エラーログ</p>
              <p>を確認してください。</p>
            </div>
          </div>
          <div className='cursor-pointer'>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.558058 0.558058C0.802136 0.313981 1.19786 0.313981 1.44194 0.558058L6 5.11612L10.5581 0.558059C10.8021 0.313981 11.1979 0.313981 11.4419 0.558059C11.686 0.802137 11.686 1.19786 11.4419 1.44194L6.88388 6L11.4419 10.5581C11.686 10.8021 11.686 11.1979 11.4419 11.4419C11.1979 11.686 10.8021 11.686 10.5581 11.4419L6 6.88388L1.44194 11.4419C1.19786 11.686 0.802136 11.686 0.558058 11.4419C0.313981 11.1979 0.313981 10.8021 0.558058 10.5581L5.11612 6L0.558058 1.44194C0.313981 1.19786 0.313981 0.802136 0.558058 0.558058Z" fill="#FACC15"/>
              </svg>
          </div>
        </div>
        <div className="category-list bg-white">
          <div className="category-list-block">
            <div className="flex flex-wrap gap-2">
              <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutm1')}>
                <div className={`${activeLayout === 'layoutm1' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                  <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white">
                  印刷待
                  </p>
                  <p className="category-badge inline-block bg-sky-600 leading-5 px-3.5 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    1
                  </p>
                </div>
              </div>
              <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutm2')}>
                <div className={`${activeLayout === 'layoutm2' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                  <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white flex">
                  <svg width="16" height="16" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" className='mr-2'>
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.26749 1.00226C6.03715 -0.331809 7.96257 -0.33181 8.73223 1.00226L13.6351 9.5006C14.4043 10.8339 13.4421 12.5 11.9027 12.5H2.09698C0.55766 12.5 -0.404626 10.8339 0.364605 9.5006L5.26749 1.00226ZM7 4.5C7.27614 4.5 7.5 4.72386 7.5 5V7.5C7.5 7.77614 7.27614 8 7 8C6.72386 8 6.5 7.77614 6.5 7.5V5C6.5 4.72386 6.72386 4.5 7 4.5ZM7 10C7.27614 10 7.5 9.77614 7.5 9.5C7.5 9.22386 7.27614 9 7 9C6.72386 9 6.5 9.22386 6.5 9.5C6.5 9.77614 6.72386 10 7 10Z" fill="#FACC15"/>
                  </svg>
                  データ作成済
                  </p>
                  <p className="category-badge inline-block bg-sky-600 leading-5 px-3.5 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    10
                  </p>
                </div>
              </div>
              <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutm3')}>
                <div className={`${activeLayout === 'layoutm3' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                  <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white">
                  自動印刷済
                  </p>
                  <p className="category-badge inline-block bg-sky-600 leading-5 px-3.5 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    3
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default MobileCategory