import React, { useState } from 'react'
import MobileCategory from '../components/category/MobileCategory';
import LayoutM2 from '../mvp2/0625-2';

function MobileRender() {
    const [show, setShow] = useState('layoutm2');
    const handleDynamicLayout=(data)=>{
      setShow(data);
    };
  return (
    <>
    <MobileCategory dynamicLayout={handleDynamicLayout}/>
    {/* {show === 'layoutm1' && <LayoutM1 />} */}
    {show === 'layoutm2' && <LayoutM2 />}
    {/* {show === 'layoutm3' && <LayoutM3 />} */}
   </>
  )
}

export default MobileRender