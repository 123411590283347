import { useState } from "react";
import CustomPicker from "../../../components/layout/datepicker";

function Filter() {

  return (
    <div>
      <div className="px-10">
        <div className="py-4">
            <div className="form-group -mx-5 -mb-4 flex flex-wrap">
              <div className="mb-4 px-5">
                <p className="mb-2">完成日</p>
                <div className="flex items-center">
                  <div className="w-[150.67px] datepicke-custom">
                    <CustomPicker option={{ placeholder: '' }}/>
                  </div>
                  <span className="px-3 font-medium font-sans">~</span>
                  <div className="w-[150.67px] datepicke-custom">
                    <CustomPicker option={{ placeholder: '' }} />
                  </div>
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[333.33px]">
                <p className="mb-2">（親）品番</p>
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text" />
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[333.33px]">
                <p className="mb-2">（子）品番</p>
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text" />
                </div>
              </div>

              <div className="mb-4 flex px-5 items-end">
              <div className="w-[80px]">
                  <button className="box-shadow-form h-9 w-full rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                  検索
                  </button>
                  </div>
              </div>
            </div>
       
        </div>
        
      </div>
    </div>
  );
}

export default Filter;
