import "../Header.css";
import { Link ,useLocation} from "react-router-dom";
import { useEffect, useState } from "react";
import {initialSubheader} from '../../constants/subHeaderData';

function SubmenuFirst({menusData,setMenus}) {
  const location = useLocation(); 
  const [url, setUrl] = useState(null);
  const [subheaderFirst,setSubheaderFirst] = useState(setMenus);
  const handleClick= (data)=> {
    menusData(data)
  }
  useEffect(() => {
    let path = location.pathname.replace(/^\//, '');
    setUrl(path);
  }, [location]);

  return (
    <>
      <div className="top-header bg-[#76D0FF] flex h-[38px] px-10">
        <div className="top-header-menu-left flex h-full gap-4">
          <div className="top-header-menu-list middle-menu h-full">
            <ul className="list-none flex h-full items-center">
              {
                setMenus?.map((item,index)=> <li key={index} className={`${url?.includes(item?.url) ? 'active' : ''} hover:bg-[#09A1EC] hover:font-bold focus:outline-sky-600 h-full flex items-center`} onClick={()=>handleClick(item)}><Link to={item.url} className="px-4 block font-bold">{item.name}</Link></li>)
              }
            </ul>
          </div>
        </div>
      </div>
    </>
   
  );
}

export default SubmenuFirst;
