import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";
import Modal from "../modal/Modal";

function Table() {
  const [dropdownOptions] = useState([
    { label: '削除', value: '' },
    { label: '処理を選択', value: 1 },
    { label: '返品入荷予定CSV出力', value: 2 },
    { label: 'バーコード印刷', value: 3 },
    { label: '手動格納', value: 4 },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全2件</span>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                    <div>
                      <button className="box-shadow-form h-9 min-w-[72px] rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600" onClick={handleOpenModal}>
                        新規登録
                      </button>
                    </div>
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr className="h-[52px]">
                    <th className="border bg-gray-100 px-3 py-2 w-[48px] font-medium">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="checkbox_all" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[37px] text-center font-medium">
                     ID
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[103px] font-medium">
                    バッジ名
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[670px] font-medium">
                      説明
                    </th>
                    <th className="border bg-gray-100 px-3 py-2  w-[120px] font-medium">
                      バッチ種類
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[48px] font-medium">
                      種別
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[90px] font-medium">
                      有効/無効
                    </th>
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[96px] font-medium">
                      <p>作成日時</p>
                      <p className="mt-1">最終更新日時</p>
                    </th>
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[84px] font-medium">
                      <p>作成者</p>
                      <p className="mt-1">最終更新者</p>
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-center align-top">
                      1
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                        SDバッチ
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>同一商品グループとそれ以外の注文に分割します。</p>
                      <p className="mt-1">5注文以上の同一商品グループをSグループに設定。</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                       SD
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    標準
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    有効
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-center align-top">
                      2
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                      11SDバッチ
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>1行1点、同一商品グループ、それ以外の注文に分割します。</p>
                      <p className="mt-1">5注文以上の同一商品グループをSグループに設定。</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                       11SD
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    標準
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    有効
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-center align-top">
                      3
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                      11バッチ
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                        1行1点の注文を分割します。
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                       11
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    標準
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    有効
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-center align-top">
                      4
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                        1バッチ
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                        1行（1点と複数点）の注文を分割します。
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                       1
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    標準
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    有効
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-center align-top">
                      5
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                      SGDバッチ
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>同一商品グループとそれ以外の注文に分割します。</p>
                      <p className="my-1">同一商品グループ商品はまとめてトータルピックします。</p>
                      <p>10注文以上の同一商品グループをSグループに設定。</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                      SGD
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    標準
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    有効
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-center">
                      6
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                      オートシップ4
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                       B2B注文以外を抽出してバッチ処理
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                       MSD
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    標準
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    有効
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>2024/05/01</p>
                      <p className="mt-1">12:00</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">伊藤太郎</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onClose={handleCloseModal} title="My Modal">
       </Modal>
    </div>
  );
}

export default Table;
