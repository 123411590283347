import React, { useState } from 'react'

function SetProductCategory({dynamicLayout}) {
  const [activeLayout,setActiveLayout] = useState('layoutP1');
  const showLayouts=(data)=>{
    dynamicLayout(data);
    setActiveLayout(data);
  }
  return (
    <div>
      <div className="px-10 py-4 category-list-hide">
        <div className="category-list bg-white">
          <div className="category-list-block">
            <div className="flex flex-wrap gap-2">
              <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutP1')}>
                <div className={`${activeLayout === 'layoutP1' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                  <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white">
                  分解指示
                  </p>
                  <p className="category-badge inline-block bg-sky-600 leading-5 px-3.5 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    3
                  </p>
                </div>
              </div>
              <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutP2')}>
                <div className={`${activeLayout === 'layoutP2' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                  <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white">
                  分解中
                  </p>
                  <p className="category-badge inline-block bg-sky-600 leading-5 px-3.5 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    20
                  </p>
                </div>
              </div>
              <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutP3')}>
                <div className={`${activeLayout === 'layoutP3' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                  <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white">
                  分解完了
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetProductCategory