import { useState } from "react";
import CustomPicker from "../../../components/layout/datepicker";
import CustomDropdown from "../../../components/layout/dropdown";
const Modal = ({ show, onClose, title, children }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="fixed w-full h-full inset-0 flex justify-center bg-gray-800 bg-opacity-20 z-50 overflow-auto">
      <div className="w-full max-w-[400px] h-[287px] my-5 transform rounded bg-white px-10 py-8 text-left align-middle shadow-md transition-all">
        <div className="modal-header relative flex items-center justify-between">
          <p className="text-[20px] font-medium text-[#111827]">完成セット組登録</p>
          <button
            type="button"
            onClick={onClose}
            className="ml-auto inline-flex items-center rounded-lg bg-white text-gray-400 hover:text-gray-900 focus:outline-sky-600"
          >
            <span className="sr-only">Close</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
              data-slot="icon"
              className="h-8 w-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              ></path>
            </svg>
          </button>
        </div>
        <div className="modal-body mt-5">
          <div className="relative">
            <table className="w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
              <tbody>
                <tr className="bg-white hover:bg-gray-100 text-[14px] ">
                  <th className="border bg-gray-100 px-3 py-3.5 h-16 w-[160px] font-medium">
                    指示数
                  </th>
                  <td className="w-[160px] border border-gray-200 px-3 py-3.5 h-16 text-sm-ch">
                    5
                  </td>
                </tr>
                <tr className="bg-white hover:bg-gray-100 text-[14px] ">
                  <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16  w-[160px] font-medium">
                    <p className="m-0 d-flex">
                      <span className="my-auto me-2">完成数</span>
                    </p>
                  </th>
                  <td className="w-[160px] border border-gray-200 px-3 h-16 py-3.5 flex border-transparent">
                    <input
                      className="w-[100px] box-shadow-form h-9 rounded border border-gray-300 px-3 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                      placeholder=""
                      type="text"
                    />
                    <p className="my-auto ms-2.5">個</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer mt-5">
          <ul className="flex flex-wrap justify-center space-x-5">
            <li className="w-[80px] h-[34px]">
              <button className="box-shadow-form h-full w-full rounded bg-sky-600 px-3 font-medium text-white focus:outline-sky-600">
                登録
              </button>
            </li>
            <li className="w-[80px] h-[34px]">
              <button
                type="button"
                className="box-shadow-form h-full w-full font-medium rounded border border-gray-300 bg-white px-3 focus:outline-sky-600"
              >
                閉じる
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Modal;