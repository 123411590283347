import './App.css';
import Header from './header/Header';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div className="logigram-main wrapper rb__wrapper leading-custom w-main text-sm font-family-medium mx-auto">
      <Header />
      <Outlet />
    </div>
  );
}

export default App;
