import { useState } from "react";
import CustomPicker from "../../../components/layout/datepicker";
import CustomDropdown from "../../../components/layout/dropdown";

function Filter() {
  const [dropdownOptions1] = useState([
    { label: '', value: 0 },
    { label: 'その他検索項目', value: 1 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '支払方法', value: 0 },
    { label: '削除（選択)', value: 1 },
    { label: 'CSV出力 (選択)', value: 2 },
    { label: '返品通知（選択）', value: 3 },
    { label: '検査手順書参照（選択）', value: 4 }
  ]);
  const [dropdownOptions3] = useState([
    { label: '返品理由', value: 0 },
    { label: '受取拒否', value: 1 },
    { label: '引取依頼', value: 2 },
    { label: '誤発送', value: 3 },
    { label: '商品不良', value: 4 },
    { label: '配達中止', value: 5 },
    { label: 'サイズが合わない', value: 6 },
    { label: 'イメージ違い', value: 7 },
    { label: '長期不在', value: 8 },
    { label: '住所不明', value: 9 },
    { label: '理由不明', value: 10 },
    { label: '色味相違', value: 11 },
    { label: '素材相違', value: 12 },
    { label: 'その他', value: 13 },
    { label: 'サイズオーバー', value: 14 },
    { label: '重量が合わない', value: 15 },
    { label: '転居先不明', value: 16 }
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[0]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[0]);
  const [selectedValue3, setSelectedValue3] = useState(dropdownOptions3[0]);
  const handleSelectChange = (newValue, setter) => {
    setter(newValue)
  }
  return (
    <div>
      <div className="px-10">
        <div className="py-4">
            <div className="form-group -mx-5 -mb-4 flex flex-wrap">
              <div className="mb-4 px-5">
                <div className="flex items-center">
                  <div className="w-[170px]">
                    <CustomPicker option={{ placeholder: '返品登録日' }}/>
                  </div>
                  <span className="px-3 font-medium font-sans">~</span>
                  <div className="w-[170px]">
                    <CustomPicker option={{ placeholder: '返品登録日' }} />
                  </div>
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[373px]">
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="テキスト" type="text" />
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[373px]">
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="テキスト" type="text" />
                </div>
              </div>

              <div className="mb-4 flex px-5">
                <div className="w-[134px] h-[36px] dropdown-ch">
                  <CustomDropdown options={dropdownOptions1} selectedValue={selectedValue1} onChange={(newVal) => handleSelectChange(newVal,setSelectedValue1)}/>
                </div>
                <div className="pl-10">
                  <button className="box-shadow-form h-9 min-w-20 rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                  検索
                  </button>
                </div>
              </div>
            </div>
            {
              selectedValue1.value == 1 &&  (
              <div className="form-group -mx-5 -mb-4 flex flex-wrap mt-4">
                <div className="mb-4 px-5">
                  <div className="flex items-center">
                    <div className="w-[170px]">
                      <CustomPicker option={{ placeholder: '出荷日' }}/>
                    </div>
                    <span className="px-3 font-medium font-sans">~</span>
                    <div className="w-[170px]">
                      <CustomPicker option={{ placeholder: '出荷日' }} />
                    </div>
                  </div>
                </div>
            <div className="mb-4 px-5">
                  <div className="flex items-center">
                    <div className="w-[170px]">
                      <CustomPicker option={{ placeholder: '最終更新日' }}/>
                    </div>
                    <span className="px-3 font-medium font-sans">~</span>
                    <div className="w-[170px]">
                      <CustomPicker option={{ placeholder: '最終更新日' }} />
                    </div>
                  </div>
                </div>
                <div className="mb-4 px-5">
                  <div className="w-[373px]">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="注文番号" type="text" />
                  </div>
                </div>
                <div className="mb-4 px-5">
                  <div className="w-[373px]">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="送り状No." type="text" />
                  </div>
                </div>
                <div className="mb-4 px-5">
                  <div className="w-[373px]">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="EMS番号" type="text" />
                  </div>
                </div>
                <div className="mb-4 px-5">
                  <div className="w-[373px]">
                    <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
                  </div>
                </div>
                <div className="mb-4 px-5">
                  <div className="w-[373px]">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="注文者、送付先" type="text" />
                  </div>
                </div>
                <div className="mb-4 px-5">
                  <div className="w-[373px]">
                    <CustomDropdown options={dropdownOptions3} selectedValue={selectedValue3}/>
                  </div>
                </div>
                <div className="mb-4 px-5">
                  <div className="w-[373px]">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="ユーザー名" type="text" />
                  </div>
                </div>
          
              
              </div>
              )
            }
        </div>
        
      </div>
    </div>
  );
}

export default Filter;
