import CustomDropdown from "../../../components/layout/dropdown";
import CustomPicker from "../../../components/layout/datepicker";
import { useState } from "react";
import Modal from "../modal/Modal";

function Table() {
  const [dropdownOptions] = useState([
    { label: '入荷検品取消', value: '' },
    { label: '処理を選択', value: 1 },
    { label: '入荷済CSV出力', value: 2 },
    { label: '入荷看板印刷', value: 3 },
    { label: 'バーコード印刷', value: 4 },
    { label: '手動格納', value: 5 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '良品', value: '' },
    { label: '良品', value: 1 },
  ]);
  const [dropdownOptions3] = useState([
    { label: '検品済', value: '' },
    { label: '検品済', value: 1 },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[1]);
  const [selectedValue3, setSelectedValue3] = useState(dropdownOptions3[1]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全2件</span>
                  <div
                    className="custom-dropdown relative inline-block min-w-[200px] text-left" >
                    <div>
                      <CustomDropdown options={dropdownOptions} selectedValue={selectedValue}/>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
            <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom">
                  <tr>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[48px]">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="checkbox_all" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[40px] align-top font-medium">
                     No
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[89px] align-top font-medium">
                    作業日時
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2  w-[142px] align-top font-medium"
                    >
                      品番
                      <br />
                      バーコード
                      <br />
                      商品名
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[104px] align-top font-medium"
                    >
                     入荷数
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[120px] align-top font-medium"
                    >
                      <p className="mt-0 mb-1 d-flex gap-2">
                      <span className="my-auto me-2">商品区分</span>
                      <span className="my-auto inline-flex items-center rounded bg-emerald-700 text-white px-2 py-1 text-xs font-medium w-10 h-[22px] py-0.5 px-2">変更</span>
                      </p>
                      <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>

                    </th>
                    <th
                     
                     className="border bg-gray-100 px-2.5 py-2 w-[120px] align-top font-medium"
                   >
                     <p className="mt-0 mb-1 d-flex gap-2">
                     <span className="my-auto me-2">商品区分</span>
                     <span className="my-auto inline-flex items-center rounded bg-emerald-700 text-white px-2 py-1 text-xs font-medium w-10 h-[22px] py-0.5 px-2">変更</span>
                     </p>
                     <CustomDropdown options={dropdownOptions3} selectedValue={selectedValue3}/>


                   </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[145px] align-top font-medium"
                    >
                      ロット
                      <br />
                      消費期限
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[144px] align-top font-medium"
                    >
                     
                      <p className="m-0 d-flex gap-2">
                      <span className="my-auto me-2"> ロケーション</span>
                      <span className="my-auto inline-flex items-center rounded bg-emerald-700 text-white px-2 py-1 text-xs font-medium w-10 h-[22px] py-0.5 px-2">変更</span>
                      </p>
                      <input className="mt-1 box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text"></input>
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[72px] align-top font-medium"
                    >
                      注文番号
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[60px] align-top font-medium"
                    >
                      返品元
                    </th>
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[60px] align-top font-medium"
                    >
                     仕入先
                    </th>
                  
                    <th
                     
                      className="border bg-gray-100 px-2.5 py-2 w-[96px] align-top font-medium"
                    >
                     備考
                     <br />
                     作業者
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-2.5 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                      1
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <p>2024/02/03</p>
                      <p>09:52:30</p>
                    </td>
                    <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                      <p>TCT001</p>
                      <p>4912345123459</p>
                      <p>商品AAA</p>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text"></input>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                    <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                    <CustomDropdown options={dropdownOptions3} selectedValue={selectedValue3}/>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    <input className="mb-1 box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text"></input>
                    <CustomPicker option={{ placeholder: 'yyyy/mm/dd' }}/>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text"></input>
              
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    伊藤たかひろ
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-2.5 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                      2
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <p>2024/02/03</p>
                      <p>09:52:30</p>
                    </td>
                    <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                      <p>TCT002</p>
                      <p>4912345123460</p>
                      <p>商品BBB</p>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text"></input>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                    <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                    <CustomDropdown options={dropdownOptions3} selectedValue={selectedValue3}/>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    <input className="mb-1 box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text"></input>
                    <CustomPicker option={{ placeholder: 'yyyy/mm/dd' }}/>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text"></input>
              
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    伊藤太郎
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                    <td className="border border-gray-200 px-2.5 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                      3
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <p>2024/02/03</p>
                      <p>09:52:30</p>
                    </td>
                    <td className="break-all border border-gray-200 px-2.5 py-2 align-top">
                      <p>TCT003</p>
                      <p>4912345123461</p>
                      <p>商品CCC</p>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text"></input>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                    <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top">
                    <CustomDropdown options={dropdownOptions3} selectedValue={selectedValue3}/>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    <input className="mb-1 box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text"></input>
                    <CustomPicker option={{ placeholder: 'yyyy/mm/dd' }}/>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text"></input>
              
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 text-right align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top"></td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                    伊藤太郎
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onClose={handleCloseModal} title="My Modal">
       </Modal>
    </div>
  );
}

export default Table;
