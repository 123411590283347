import React, { useState } from 'react'
import CustomDropdown from '../../../components/layout/dropdown'

function Filter() {
  const [dropdownOptions1] = useState([
    { label: '', value: 0 },
    { label: '荷主名', value: 1 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '', value: 0 },
    { label: '印刷種類', value: 1 },
  ]);
  const [dropdownOptions3] = useState([
    { label: '', value: 0 },
    { label: '2月8日（当日）', value: 1 },
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[1]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[1]);
  const [selectedValue3, setSelectedValue3] = useState(dropdownOptions3[1]);

  const handleSelectChange = (newValue, setter) => {
    setter(newValue)
  }
  return (
    <div>
      <div className="px-10">
        <div className="py-4">
            <div className="form-group -mx-5 -mb-4 flex flex-wrap">
              <div className="mb-4 px-5">
                  <div className="w-[266px]">
                    <CustomDropdown options={dropdownOptions1} selectedValue={selectedValue1}/>
                  </div>
              </div>
              <div className="mb-4 px-5">
                  <div className="w-[266px]">
                      <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
                  </div>
              </div>
              <div className="mb-4 pl-5">
                  <div className="w-[136px]">
                      <CustomDropdown options={dropdownOptions3} selectedValue={selectedValue3}/>
                  </div>
              </div>
              <div className="mb-4 pl-4 pr-5 flex">
                <div className="flex items-center">
                  <div className="w-[48px]">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="8" type="text" />
                  </div>
                  <span className="px-1 font-medium font-sans">~</span>
                  <div className="w-[48px]">
                    <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="17" type="text" />
                  </div>
                </div>
                <p className='mb-0 flex items-end'>時</p>
              </div>
              <div className="mb-4 flex px-5">
                  <button className="box-shadow-form h-9 min-w-20 rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                  検索
                  </button>
              </div>
            </div>
        </div>
        
      </div>
    </div>
  )
}

export default Filter