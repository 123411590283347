import { useState } from 'react';
import MainCategory from '../components/category/MainCategory';
import Layout from '../mvp2/0614-1/index';
import Layout2 from '../mvp2/0614-2/index';
import Layout3 from '../mvp2/0614-3/index';

function DataRender() {
  const [show, setShow] = useState('layout');
  const handleDynamicLayout=(data)=>{
    setShow(data);
  };
  return (
   <>
    <MainCategory dynamicLayout={handleDynamicLayout}/>
    {show === 'layout' && <Layout />}
    {show === 'layout2' && <Layout2 />}
    {show === 'layout3' && <Layout3 />}
   </>
  );
}

export default DataRender;
