

function Filter() {
  
  return (
    <div>
     <div className="px-10 pt-4">
            <div className="form-group flex flex-wrap items-center">
                <div className="mb-4 flex items-center">
                    <input className="w-[253.33px] box-shadow-form h-9 rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="（親）品番・商品名・バーコード" type="text" />
                    <div className="inline-flex items-center w-[72px] ml-2">
                        <input id="checkbox-1" className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600" type="checkbox" value="" />
                        <label htmlFor="checkbox-1" className="ms-2 font-medium text-gray-900" >部分一致</label>
                    </div>
                </div>
                <div className="mb-4 flex items-center mx-10">
                    <input className="w-[253.33px] box-shadow-form h-9 rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="（子）品番・商品名・バーコード" type="text" />
                    <div className="inline-flex items-center w-[72px] ml-2">
                        <input id="checkbox-1" className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600" type="checkbox" value="" />
                        <label htmlFor="checkbox-1" className="ms-2 font-medium text-gray-900" >部分一致</label>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="w-[333.33px]">
                        <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="カテゴリ" type="text" />
                    </div>
                   
                </div>
               
                <div className="mb-4 w-[80px] items-center ml-10">
                    <button className="box-shadow-form h-9 w-full rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">検索</button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Filter;
