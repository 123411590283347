import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";
import Modal from "../modal/Modal";
import Modal2 from "../modal/Modal2";
function Table() {
  const [dropdownOptions] = useState([
    { label: '---処理を選択---', value: '0' },
    { label: '削除（選択）​', value: 1 },
    { label: '削除（検索全件）', value: 2 }
  ]);
  const [dropdownOptions2] = useState([
    { label: '新規登録', value: 0 },
    { label: '一括登録', value: 1 }
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[0]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSelectChange = (newValue, setter) => {
    setter(newValue)
    if(newValue.value === 0){
      handleOpenModal();
    }
 
  }
  const handleOpenModal2 = () => {
    setShowModal2(true);
  };
  const handleCloseModal2 = () => {
    setShowModal2(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
              <div className="flex items-center">
                  <span className="mr-5 inline-block">全3件</span>
                  <div
                    className="custom-dropdown relative inline-block min-w-[200px] text-left" >
                    <div>
                      <CustomDropdown options={dropdownOptions} selectedValue={selectedValue}/>
                    </div>
                  </div>
                </div>
           
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                  
                  <button className="box-shadow-form h-9 min-w-20 rounded bg-emerald-700 px-3 py-2 font-bold text-white focus:outline-emerald-600">ロケからマスタ作成​</button>
           
                  <div
                    className="custom-dropdown custom-dropdown-blue relative inline-block min-w-[98px] text-left" >
                    <div>
                      <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2} onChange={(newVal) => handleSelectChange(newVal,setSelectedValue2)}/>
                    </div>
                  </div>
                  
                  <div className="flex items-center justify-end gap-5">
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  </div>
              
                </div>

               
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr>
                  <th className="border bg-gray-100 px-2.5 py-2 w-[48px] font-medium">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="checkbox_all" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[146px] align-top font-bold">
                    操作​ 
                    </th>
                    <th className="border bg-gray-100 px-2.5 w-[138px] py-2 align-top font-bold">
                    <div class="flex items-center"> エリア<p class="icon-sort inline-block ms-auto"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" class="h-4 w-4 text-gray-400"><path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd"></path></svg></p></div>
                    </th>
                    <th className="border bg-gray-100 px-2.5 w-[138px] py-2 align-top font-bold">
                    <div class="flex items-center"> ゾーン<p class="icon-sort inline-block ms-auto"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" class="h-4 w-4 text-gray-400"><path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd"></path></svg></p></div>
                    </th>
                    <th className="border bg-gray-100 px-2.5 w-[152px] py-2 align-top font-bold">
                    ロケーション
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[197px] align-top font-bold">
                    
                    <div class="flex items-center"> ロケレベル​ <p class="icon-sort inline-block ms-auto"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" class="h-4 w-4 text-gray-400"><path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd"></path></svg></p></div>
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[325px] align-top font-bold">
                    最終更新 
                    </th>
                    <th className="border bg-gray-100 px-2.5 py-2 w-[90px] align-top font-bold">
                    作業者​
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white">
                  <td className="border border-gray-200 px-2.5 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <div className="flex">
                      <button class="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px] me-2" onClick={handleOpenModal2}>編集</button>
                      <button class="box-shadow-form h-6 rounded bg-rose-600 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">必須</button>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    利用しない​
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    利用しない​ 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    11-01-01-01 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    設定しない
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    2024-02-01 12:12:55​
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    <button className="text-sky-600 underline focus:outline-sky-600">
                    namlh03
                      </button>
                    </td>
                  </tr>
             
                  <tr className="bg-white">
                  <td className="border border-gray-200 px-2.5 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <div className="flex">
                      <button class="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px] me-2" onClick={handleOpenModal2}>編集</button>
                      <button class="box-shadow-form h-6 rounded bg-rose-600 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">必須</button>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    利用しない​
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    利用しない​ 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    11-01-01-02
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    設定しない
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    2024-02-01 12:12:55​
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    <button className="text-sky-600 underline focus:outline-sky-600">
                    namlh03
                      </button>
                    </td>
                  </tr>

                  
                  <tr className="bg-white">
                  <td className="border border-gray-200 px-2.5 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 align-top">
                      <div className="flex">
                      <button class="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px] me-2" onClick={handleOpenModal2}>編集</button>
                      <button class="box-shadow-form h-6 rounded bg-rose-600 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">必須</button>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    1:ロボット​
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    001：GPT 
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    11-01-01-03
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    R:ロボット​
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    2024-02-01 12:12:55​
                    </td>
                    <td className="border border-gray-200 px-2.5 py-2 text-left align-top">
                    <button className="text-sky-600 underline focus:outline-sky-600">
                    namlh03
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onClose={handleCloseModal} title="My Modal">
       </Modal>
       <Modal2 show={showModal2} onClose={handleCloseModal2} title="My Modal">
       </Modal2>
    </div>
  );
}

export default Table;
