import Filter from './filter/index';
import Table from './table/Table';

function Layout3() {
  return (
   <>
    <Filter />
    <Table />
   </>
  );
}

export default Layout3;
