import React, { useState } from 'react'
import Table from './table/Table';

function Layout8 () {

  return (
    <div>
      <Table />
    </div>
  )
}

export default Layout8