import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";

function Table() {
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
            <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right whitespace-nowrap">
              <thead className="text-sm uppercase leading-custom font-medium">
                <tr className="border border-gray-200 bg-gray-100">
                  <th className="border bg-gray-100 px-3 w-[60px] align-middle font-medium h-[68px]" rowSpan="2">
                   エリア
                  </th>
                  <th className="border bg-gray-100 px-3 w-[390px] align-middle font-medium h-[68px]" rowSpan="2">
                    <div className="flex justify-between items-center">
                      <p className="mb-0">荷主 (ID)</p> 
                      <div>
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M2.64645 0.146447C2.84171 -0.0488155 3.15829 -0.0488155 3.35355 0.146447L5.85355 2.64645C6.04882 2.84171 6.04882 3.15829 5.85355 3.35355C5.65829 3.54882 5.34171 3.54882 5.14645 3.35355L3 1.20711L0.853553 3.35355C0.658291 3.54882 0.341709 3.54882 0.146447 3.35355C-0.0488155 3.15829 -0.0488155 2.84171 0.146447 2.64645L2.64645 0.146447ZM0.146447 6.64645C0.341709 6.45118 0.658291 6.45118 0.853553 6.64645L3 8.79289L5.14645 6.64645C5.34171 6.45118 5.65829 6.45118 5.85355 6.64645C6.04882 6.84171 6.04882 7.15829 5.85355 7.35355L3.35355 9.85355C3.15829 10.0488 2.84171 10.0488 2.64645 9.85355L0.146447 7.35355C-0.0488155 7.15829 -0.0488155 6.84171 0.146447 6.64645Z" fill="#6B7280"/>
                        </svg>
                      </div>
                    </div>
                  </th>
                  <th className="border bg-gray-100 px-3 w-[110px] align-middle font-medium h-[68px]" rowSpan="2">
                    <div className="flex justify-between items-center">
                        <p className="mb-0">出荷計画数</p> 
                        <div>
                          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2.64645 0.146447C2.84171 -0.0488155 3.15829 -0.0488155 3.35355 0.146447L5.85355 2.64645C6.04882 2.84171 6.04882 3.15829 5.85355 3.35355C5.65829 3.54882 5.34171 3.54882 5.14645 3.35355L3 1.20711L0.853553 3.35355C0.658291 3.54882 0.341709 3.54882 0.146447 3.35355C-0.0488155 3.15829 -0.0488155 2.84171 0.146447 2.64645L2.64645 0.146447ZM0.146447 6.64645C0.341709 6.45118 0.658291 6.45118 0.853553 6.64645L3 8.79289L5.14645 6.64645C5.34171 6.45118 5.65829 6.45118 5.85355 6.64645C6.04882 6.84171 6.04882 7.15829 5.85355 7.35355L3.35355 9.85355C3.15829 10.0488 2.84171 10.0488 2.64645 9.85355L0.146447 7.35355C-0.0488155 7.15829 -0.0488155 6.84171 0.146447 6.64645Z" fill="#6B7280"/>
                          </svg>
                        </div>
                      </div>
                  </th>
                  <th className="border bg-gray-100 px-3 w-[104px] align-middle font-medium h-[68px]" rowSpan="2">
                    <div className="flex justify-between items-center">
                        <p className="mb-0">出荷実績数</p> 
                        <div>
                          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2.64645 0.146447C2.84171 -0.0488155 3.15829 -0.0488155 3.35355 0.146447L5.85355 2.64645C6.04882 2.84171 6.04882 3.15829 5.85355 3.35355C5.65829 3.54882 5.34171 3.54882 5.14645 3.35355L3 1.20711L0.853553 3.35355C0.658291 3.54882 0.341709 3.54882 0.146447 3.35355C-0.0488155 3.15829 -0.0488155 2.84171 0.146447 2.64645L2.64645 0.146447ZM0.146447 6.64645C0.341709 6.45118 0.658291 6.45118 0.853553 6.64645L3 8.79289L5.14645 6.64645C5.34171 6.45118 5.65829 6.45118 5.85355 6.64645C6.04882 6.84171 6.04882 7.15829 5.85355 7.35355L3.35355 9.85355C3.15829 10.0488 2.84171 10.0488 2.64645 9.85355L0.146447 7.35355C-0.0488155 7.15829 -0.0488155 6.84171 0.146447 6.64645Z" fill="#6B7280"/>
                          </svg>
                        </div>
                      </div>
                  </th>
                  <th className="border bg-gray-100 px-3 w-[60px] align-middle font-medium h-[68px]" rowSpan="2">
                   予実％
                  </th>
                  <th className="border bg-gray-100 px-3 w-[72px] align-middle font-medium h-[68px]" rowSpan="2">
                    引当時間
                  </th>
                  <th className="border bg-gray-100 px-3 w-[72px] align-middle font-medium h-[68px]" rowSpan="2">
                    引当回数
                  </th>
                  <th className="border bg-gray-100 px-3 w-[300px] align-middle text-center font-medium h-[34px]" colSpan="5">
                    進捗状況
                  </th>
                  <th className="border bg-gray-100 px-3 w-[72px] align-middle font-medium h-[68px]" rowSpan="2">
                    進捗率％
                  </th>
                </tr>
                <tr className="border border-gray-200 bg-gray-100">
                  <th className="border bg-gray-300 px-0 w-[60px] align-middle text-center font-medium h-[34px]">
                    進捗状況
                  </th>
                  <th className="border bg-gray-300 px-0 w-[60px] align-middle text-center font-medium h-[34px]">
                    進捗状況
                  </th>
                  <th className="border bg-gray-300 px-0 w-[60px] align-middle text-center font-medium h-[34px]">
                    進捗状況
                  </th>
                  <th className="border bg-[#E0F2FE] px-0 w-[60px] align-middle text-center font-medium h-[34px]">
                    進捗状況
                  </th>
                  <th className="border bg-[#BAE6FD] px-0 w-[60px] align-middle text-center font-medium h-[34px]">
                    進捗状況
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-gray-200 bg-white h-[44px]">
                  <td className="border border-gray-200 px-2.5 py-2" rowSpan="3">
                  </td>
                  <td className="border border-gray-200 px-3 py-2 text-left align-top" rowSpan="3">
                  ABC書店 (1235)
                  </td>
                  <td className="border border-gray-200 px-2 py-3.5 align-top" rowSpan="3">
                    <div className='w-[94px] h-[36px]'>
                      <button className="box-shadow-form h-full w-full rounded text-gray-900 border border-[#D1D5DB] text-right pr-3">
                      1000
                      </button>
                    </div>
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 text-right" >
                    100
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right" rowSpan="3">
                   90
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                  14:00
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                    3
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 text-right">
                   100
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 text-right">
                    0
                  </td>
                </tr>
                <tr className="border border-gray-200 bg-white h-[44px]">
                  <td className="border border-gray-200 px-2.5 py-3.5 text-right">
                    600
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                  11:00
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                    2
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                    100
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                    500
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 text-right">
                    17
                  </td>
                </tr>
                <tr className="border border-gray-200 bg-white h-[44px]">
                  <td className="border border-gray-200 px-2.5 py-3.5 text-right">
                    200
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                  9:00
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                  1
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top ">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 align-top text-right">
                    200
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 text-right">
                    100
                  </td>
                </tr>
                <tr className="border border-[#E5E7EB] bg-white h-[44px]">
                  <td className="border border-gray-200 px-3 py-2 h-[44px]">
                  </td>
                  <td className="border border-gray-200 px-3 py-2 h-[44px] align-top">
                  Xアパレル (1235)
                  </td>
                  <td className="border border-gray-200 px-2 py-3.5 text-right h-[44px]">
                   <div className='w-[94px] h-[36px]'>
                      <button className="box-shadow-form h-full w-full rounded text-gray-900 border border-[#D1D5DB] text-right pr-3">
                      500
                      </button>
                    </div>
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 text-right h-[44px]">
                    400
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 text-right h-[44px]">
                   80
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 h-[44px] text-right ">
                   12.00
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 text-right h-[44px]">
                    1
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 h-[44px]">
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 text-right h-[44px]">
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 text-right h-[44px]">
                   100
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 text-right text-right h-[44px]">
                    50
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 text-right h-[44px]">
                    250
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 text-right h-[44px]">
                   50
                  </td>
                </tr>
                <tr className="border border-[#E5E7EB] bg-[#F3F4F6] h-[34px]">
                  <td className="border border-[#E5E7EB] px-3 py-2">
                  合計
                  </td>
                  <td className="border border-[#E5E7EB] px-3 py-2">
                  </td>
                  <td className="border border-[#E5E7EB] px-3 py-3.5 text-right">
                    1500
                  </td>
                  <td className="border border-[#E5E7EB] px-3 py-3.5 text-right">
                    1300
                  </td>
                  <td className="border border-[#E5E7EB] px-3 py-3.5 align-top text-right">
                   87
                  </td>
                  <td className="border border-[#E5E7EB] px-3 py-3.5 align-top">
                   
                  </td>
                  <td className="border border-[#E5E7EB] px-3 py-3.5 align-top text-right">
                    -
                  </td>
                  <td className="border border-[#E5E7EB] px-3 py-3.5 align-top">
                   
                  </td>
                  <td className="border border-[#E5E7EB] px-3 py-3.5 text-right">
                    100
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 align-top text-right">
                   100
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 align-top text-right text-right">
                    150
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 align-top text-right">
                    950
                  </td>
                  <td className="border border-gray-200 px-3 py-3.5 text-right">
                   50
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Table;
