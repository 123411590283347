import React from 'react'
import Table from './table/Table'
import Filter from './filter/index';

function Layout10() {
  return (
    <>
    <Filter />
    <Table />
    </>
  )
}

export default Layout10