import React from 'react'
import Filter from './filter'
import Table from './table/Table'

function LayoutOL() {
  return (
    <>
    <Filter />
    <Table />
    </>
  )
}

export default LayoutOL