import { useState } from "react";
import CustomPicker from "../../../components/layout/datepicker";
import CustomDropdown from "../../../components/layout/dropdown";
const Modal = ({ show, onClose, title, children }) => {
  const [dropdownOptions1] = useState([
    { label: '出荷停止', value: 0 },
    { label: '出荷停止', value: 1 },
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[1]);
  if (!show) {
    return null;
  }

  return (
    <div className="fixed w-full h-full inset-0 flex justify-center bg-gray-800 bg-opacity-20 z-50 overflow-auto">
      <div className="w-full max-w-[584px] h-[230px] my-5 transform rounded bg-white px-10 py-8 text-left align-middle shadow-md transition-all">
          <div className="modal-header relative flex items-center justify-between">
            <p className="text-xl font-medium text-gray-900">マスター登録</p>
            <button
              type="button"
              onClick={onClose}
              className="ml-auto inline-flex items-center rounded-lg bg-white text-gray-400 hover:text-gray-900 focus:outline-sky-600"
            >
              <span className="sr-only">Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="h-8 w-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                ></path>
              </svg>
            </button>
          </div>
          <div className="modal-body mt-5">
            <div className="relative">
              <table className="w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <tbody>
           

                  
                  <tr className="bg-white hover:bg-gray-100">
                    <th className="border bg-gray-100 px-3 py-3.5 text-sm-ch h-16 text-right w-[160px] font-medium">
                    <span className="my-auto me-2">CSV 箱マスタ</span>
                        <span className="my-auto inline-flex items-center rounded bg-rose-600 text-white px-2 py-1 text-xs w-10 h-[22px] py-0.5 px-2 font-bold text-xs">
                          必須
                        </span>
                    </th>
                    <td className="w-[344px] border border-gray-200 px-3 h-16 py-3.5">
                    <input type="file" accept="image/png, image/jpeg" />
                    </td>
                  </tr>

                

                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer mt-5">
            <ul className="flex flex-wrap justify-center space-x-5">
              <li className="w-[80px] h-[34px]">
                <button className="box-shadow-form h-full w-full rounded bg-sky-600 px-3 font-medium text-white focus:outline-sky-600">
                Save
                </button>
              </li>
              {/* <li className="w-[80px] h-[34px]">
                <button
                  type="button"
                  className="box-shadow-form h-full w-full font-medium rounded border border-gray-300 bg-white px-3 focus:outline-sky-600"
                >
                  閉じる
                </button>
              </li> */}
            </ul>
          </div>
      </div>
    </div>
  );
};
 
export default Modal;