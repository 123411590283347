import { useState } from "react";
import CustomDropdown from "../../../components/layout/dropdown";

function Filter() {
  const [dropdownOptions1] = useState([
    { label: 'エリア​', value: 0 },
  ]);
  const [dropdownOptions2] = useState([
    { label: 'ゾーン​', value: 0 },
  ]);
  const [dropdownOptions3] = useState([
    { label: 'ロケレベル', value: 0 },
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[0]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[0]);
  const [selectedValue3, setSelectedValue3] = useState(dropdownOptions3[0]);
  return (
    <div>
      <div className="px-10 mt-4 bg-[#F3F4F6]">
        <div className="form-group flex flex-wrap">
          <p className="px-3 py-4 my-auto">初期設定：</p>
          <p className="px-3 py-4 my-auto bg-[#E0F2FE] font-bold">
            ロケマスタを利用する　？
          </p>
          <p className="px-3 py-4 my-auto d-flex custom-radio">
             
            <input
              type="radio"
              className="top-[1px] relative mr-2 h-3 w-3 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer" name="id" id="id1" checked={true}
            />
            <label className="my-auto" htmlFor="id1">ロケマスタを利用しない　</label>
          </p>
          <p className="px-3 py-4 my-auto d-flex custom-radio">
             
             <input
               type="radio"
               className="top-[1px] relative mr-2 h-3 w-3 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer" name="id" id="id2"
             />
             <label className="my-auto" htmlFor="id2">ロケマスタを利用する</label>
           </p>
        
          <p className="px-3 py-4 my-auto">
            (● 独自ロケ採番ルール 〇 独自ロケ採番ルール）​​
          </p>
        </div>
      </div>
      <div className="px-0 mt-4">
        <div className="pt-0">
          <div className="form-group flex flex-wrap">
            <div className="mb-4 px-5">
              <div className="w-[120px]">
                <div className="h-[36px] dropdown-ch">
                  <CustomDropdown
                    options={dropdownOptions1}
                    selectedValue={selectedValue1}
                  />
                </div>
              </div>
            </div>
            <div className="mb-4 px-5">
              <div className="w-[120px]">
                <div className="h-[36px] dropdown-ch">
                  <CustomDropdown
                    options={dropdownOptions2}
                    selectedValue={selectedValue2}
                  />
                </div>
              </div>
            </div>
            <div className="mb-4 px-5">
              <div className="w-[270px]">
                <input
                  value="ロケーション"
                  className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600"
                  placeholder="ロケーション"
                  type="text"
                />
              </div>
            </div>
            <div className="mb-4 pe-5">
              <div className="flex h-[36px] items-center">□部分一致​</div>
            </div>

            <div className="mb-4 px-5">
              <div className="w-[170.67px]">
                <div className="h-[36px] dropdown-ch">
                  <CustomDropdown
                    options={dropdownOptions3}
                    selectedValue={selectedValue3}
                  />
                </div>
              </div>
            </div>

            <div className="mb-4 flex px-5 items-end">
              <div className="w-[80px]">
                <button className="box-shadow-form h-9 w-full rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                  検索
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5"></div>
    </div>
  );
}

export default Filter;
