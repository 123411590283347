import Filter from './filter/index';
import Table from './table/Table';
function Layout4() {
  return (
   <>
    <Filter />
    <Table />
    <style>{"\
        .category-list,.bottom-header-1{\
          display:none!important;\
        }\
        \
"}</style>
   </>
  );
}

export default Layout4;
