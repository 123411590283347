import { useState } from "react";
import CustomDropdown from "../../../components/layout/dropdown";

function Filter() {
  const [dropdownOptions1] = useState([
    { label: '選択してください', value: 0 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '選択してください', value: 0 },
  ]);
  const [dropdownOptions3] = useState([
    { label: '選択してください', value: 0 },
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[0]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[0]);
  const [selectedValue3, setSelectedValue3] = useState(dropdownOptions3[0]);
  return (
    <div>
      <div className="px-10 my-4">
        <div className="pt-4 border">
          
            <div className="form-group flex flex-wrap">
              <div className="mb-4 px-5">
                <div className="w-[198px]">
                <p className="mb-2">支払方法ID</p>
                  <input value="支払方法ID" className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text" />
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[198px]">
                <p className="mb-2">種別</p>
                  <div className="h-[36px] dropdown-ch">
                  <CustomDropdown options={dropdownOptions1} selectedValue={selectedValue1}/>
                </div>
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[198px]">
                <p className="mb-2">支払方法名</p>
                  <input value="支払方法名" className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text" />
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[198px]">
                <p className="mb-2">利用</p>
                  <div className="h-[36px] dropdown-ch">
                    <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
                  </div>
                </div>
              </div>

            </div>
     
        </div>
        
      </div>
      <div className="px-10">
      <div className="my-4 flex px-0 items-end">
              <div className="w-[80px]">
                  <button className="box-shadow-form h-9 w-full rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                  検索
                  </button>
                  </div>
              </div>
              </div>
    </div>
  );
}

export default Filter;
