import { useState } from "react";

function Filter() {

  return (
    <div>
      <div className="px-10 my-4">
      <h2 className="mb-3 text-lg">納品書設定 </h2>
        <div className="pt-4 border">
          
            <div className="form-group flex flex-wrap">
              <div className="mb-4 px-5">
                <div className="w-[198px]">
                <p className="mb-2">納品書ID</p>
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text" value="納品書ID"/>
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[198px]">
                <p className="mb-2">納品書名</p>
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text" value="納品書名"/>
                </div>
              </div>
              <div className="mb-4 px-5">
                <div className="w-[198px]">
                <p className="mb-2">説明</p>
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="" type="text" value="説明"/>
                </div>
              </div>
            </div>
        </div>
        
      </div>
      <div className="px-10">
      <div className="my-4 flex px-0 items-end">
              <div className="w-[80px]">
                  <button className="box-shadow-form h-9 w-full rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                  検索
                  </button>
                  </div>
              </div>
              </div>
    </div>
  );
}

export default Filter;
