import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";

function Table() {
  const [dropdownOptions] = useState([
    { label: '制作開始', value: 1 },
    { label: '完成報告（制作中のみ）', value: 2 },
    { label: '制作指示書印刷（選択）', value: 3 },
    { label: '制作指示書印刷（検索全件）', value: 4 },
    { label: '（親）バーコード印刷（選択）', value: 5 },
    { label: 'CSVダウンロード（選択）', value: 6 },
    { label: 'CSVダウンロード（検索全件）', value: 7 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '表示切替', value: 0 },
    { label: '表示切替', value: 1 }
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[0]);

  const [pageValue, setPageValue] = useState(pageOptions[0]);

  return (
    <div className="Table">
      <div className="px-5">
        <div className="py-4">
          <div className="mb-4 text-gray-900">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="mr-5 inline-block">全2件</span>
                <div
                  className="custom-dropdown relative inline-block min-w-[110px] text-left" >
                  <div>
                    <CustomDropdown options={dropdownOptions} selectedValue={selectedValue} />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end">
                <div className="flex items-center justify-end gap-5">

                  <div className="w-[98px]">
                    <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2} />
                  </div>
                  <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue} />
                  </div>
                  <nav
                    className="box-shadow-form h-9">
                    <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                      <li>
                        <a
                          href="#"
                          className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                            data-slot="icon"
                            className="h-5 w-5 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 19.5 8.25 12l7.5-7.5"
                            ></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <span
                          aria-current="page"
                          className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                        >
                          1/4
                        </span>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                            data-slot="icon"
                            className="h-5 w-5 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m8.25 4.5 7.5 7.5-7.5 7.5"
                            ></path>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
            <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
              <thead className="bg-gray-100 text-xs uppercase">
                <tr className="text-[14px]">
                  <th className="w-[48px] h-[95px] border bg-gray-100 align-middle font-medium px-3 py-3.5">
                    <div className="flex items-center justify-center">
                      <input id="checkbox_all" className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600" type="checkbox" />
                      <label htmlFor="checkbox_all" className="sr-only">checkbox</label>
                    </div>
                  </th>
                  <th className="w-[80px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">指示CD</th>
                  <th className="w-[121px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">ステータス</th>
                  <th className="w-[118px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">
                    <div className="flex items-center">(親）品番
                      <p className="icon-sort inline-block ms-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-4 w-4 text-gray-400"><path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd"></path></svg>
                      </p>

                    </div>
                    <div className="w-full mt-2 truncate">(親）バーコード</div>
                  </th>
                  <th className="w-[132px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">
                    <div className="flex items-center">(親）商品名
                      <p className="icon-sort inline-block ms-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-4 w-4 text-gray-400"><path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd"></path></svg>
                      </p>
                    </div>
                  </th>
                  <th className="w-[118px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">
                    <div className="flex items-center">(（子）品番
                      <p className="icon-sort inline-block ms-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-4 w-4 text-gray-400"><path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd"></path></svg>
                      </p>
                    </div>
                  </th>
                  <th className="w-[118px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">
                    <div className="flex items-center">(（子）品名
                      <p className="icon-sort inline-block ms-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" className="h-4 w-4 text-gray-400"><path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd"></path></svg>
                      </p>
                    </div>
                  </th>
                  <th className="w-[66px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">指示数</th>
                  <th className="w-[66px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">完成数</th>
                  <th className="w-[52px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">残数</th>
                  <th className="w-[66px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">ロット</th>
                  <th className="w-[82px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">消費期限</th>
                  <th className="w-[107px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">
                    <div className="w-full">
                      指示日
                    </div>
                    <div className="w-full mt-2">
                      納期
                    </div>
                    <div className="w-full mt-2">
                      完成日
                    </div>
                  </th>
                  <th className="w-[66px] h-[95px] border bg-gray-100 align-top font-medium px-3 py-3.5">作業者</th>
                </tr>
              </thead>
              <tbody className="leading-custom text-sm">
                <tr className="bg-white text-[14px]">
                  <td className="border border-gray-200 px-3 py-3.5 h-[95px]">
                    <div className="flex items-center justify-center">
                      <input id="1" className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600" type="checkbox" />
                      <label htmlFor="1" className="sr-only">checkbox</label>
                    </div>
                  </td>
                  <td className="font-bold border border-gray-200 text-left align-top px-3 py-3.5 h-[95px]">
                    SD0001
                  </td>
                  <td className="font-bold border border-gray-200 text-left px-3 py-3.5 align-top h-[95px]">指示</td>
                  <td className="font-bold border border-gray-200 text-left px-3 py-3.5 align-top h-[95px]">
                    <div className="w-full">
                      TCT001
                    </div>
                    <div className="w-full mt-2 truncate">
                      4912345123459
                    </div>

                  </td>
                  <td className="font-bold border border-gray-200 text-left px-3  py-3.5 align-top h-[95px]">商品AAA</td>
                  <td className="font-bold border border-gray-200 text-left px-3 py-3.5 align-top h-[95px]"></td>
                  <td className="font-bold border border-gray-200 text-left px-3 py-3.5 align-top h-[95px]"></td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]">5</td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]">0</td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]">5</td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]"></td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]"></td>
                  <td className="font-bold border border-gray-200 text-left px-3 py-3.5 align-top h-[95px]">
                    <div className="w-full">
                      2024/02/03
                    </div>
                    <div className="w-full mt-2">
                      2024/02/10
                    </div>
                  </td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]"></td>
                </tr>
                <tr className="bg-gray-100 text-[14px]">
                  <td className="border border-gray-200 px-3 py-3.5 h-[45px]"></td>
                  <td className="border border-gray-200 text-left align-top px-3 py-3.5 h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3  py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    TCT002
                  </td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    商品BBB
                  </td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]">
                    5
                  </td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]">111</td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                </tr>
                <tr className="bg-gray-100 text-[14px]">
                  <td className="border border-gray-200 px-3 py-3.5 h-[45px]"></td>
                  <td className="border border-gray-200 text-left align-top px-3 py-3.5 h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3  py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    TCT003
                  </td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    商品CCC
                  </td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]">
                    10
                  </td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]">222</td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                </tr>
                <tr className="bg-gray-100 text-[14px]">
                  <td className="border border-gray-200 px-3 py-3.5 h-[45px]"></td>
                  <td className="border border-gray-200 text-left align-top px-3 py-3.5 h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3  py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    TCT004
                  </td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    商品CCC
                  </td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]">
                    5
                  </td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]">333</td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                </tr>
                <tr className="bg-white text-[14px]">
                  <td className="border border-gray-200 px-3 py-3.5 h-[95px]">
                    <div className="flex items-center justify-center">
                      <input id="1" className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600" type="checkbox" />
                      <label htmlFor="1" className="sr-only">checkbox</label>
                    </div>
                  </td>
                  <td className="font-bold border border-gray-200 text-left align-top px-3 py-3.5 h-[95px]">
                    SD0002
                  </td>
                  <td className="font-bold border border-gray-200 text-left px-3 py-3.5 align-top h-[95px]">指示</td>
                  <td className="font-bold border border-gray-200 text-left px-3 py-3.5 align-top h-[95px]">
                    <div className="w-full">
                      TCT005
                    </div>

                    <div className="w-full mt-2 truncate">
                      4912345123459
                    </div>
                  </td>
                  <td className="font-bold border border-gray-200 text-left px-3  py-3.5 align-top h-[95px]">商品DDD</td>
                  <td className="font-bold border border-gray-200 text-left px-3 py-3.5 align-top h-[95px]"></td>
                  <td className="font-bold border border-gray-200 text-left px-3 py-3.5 align-top h-[95px]"></td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]">10</td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]">0</td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]">10</td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]"></td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]"></td>
                  <td className="font-bold border border-gray-200 text-left px-3 py-3.5 align-top h-[95px]">
                    <div className="w-full">
                      2024/02/03
                    </div>
                    <div className="w-full mt-2">
                      2024/02/11
                    </div>
                  </td>
                  <td className="font-bold border border-gray-200 text-right px-3 py-3.5 align-top h-[95px]"></td>
                </tr>
                <tr className="bg-gray-100 text-[14px]">
                  <td className="border border-gray-200 px-3 py-3.5 h-[45px]"></td>
                  <td className="border border-gray-200 text-left align-top px-3 py-3.5 h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3  py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    TCT006
                  </td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    商品DDD
                  </td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]">
                    20
                  </td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    2024/03
                  </td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                </tr>
                <tr className="bg-gray-100 text-[14px]">
                  <td className="border border-gray-200 px-3 py-3.5 h-[45px]"></td>
                  <td className="border border-gray-200 text-left align-top px-3 py-3.5 h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3  py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    TCT007
                  </td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]">
                    商品EEE
                  </td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]">
                    10
                  </td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-left px-3 py-3.5 align-top h-[45px]"></td>
                  <td className="border border-gray-200 text-right px-3 py-3.5 align-top h-[45px]"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            <div className="flex items-center justify-end gap-5">
              <div className="w-[64px]">
                <CustomDropdown options={pageOptions} selectedValue={pageValue} />
              </div>
              <nav className="box-shadow-form h-9" aria-label="Table navigation">
                <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                  <li>
                    <a
                      href="#"
                      className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        data-slot="icon"
                        className="h-5 w-5 text-gray-400"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 19.5 8.25 12l7.5-7.5"
                        ></path>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <span
                      aria-current="page"
                      className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                    >
                      1/4
                    </span>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        data-slot="icon"
                        className="h-5 w-5 text-gray-400"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m8.25 4.5 7.5 7.5-7.5 7.5"
                        ></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
