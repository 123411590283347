import Filter from './filter/index';
import Table from './table/Table';

function Layout6() {
  return (
    <>
      <Filter />
      <Table />
    </>
  );
}

export default Layout6;
