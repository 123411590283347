import React,{useState} from 'react'
import Table from './table/Table'
import { Link ,useLocation} from "react-router-dom";
import CustomDropdown from "../../components/layout/dropdown";

function LayoutSM3() {
  const [dropdownOptions] = useState([
    { label: '1: 配送2日間（佐川急便：北海道、東北、九州、沖縄）', value: '' },
  ]);
  const menus = [
    { id: 1, name: '保存検索ルール', active: false, url: '' },
    { id: 2, name: '保存抽出マスタ', active: true, url: '' },
    { id: 3, name: '詳細検索', active: false, url: '' },
    { id: 4, name: '印刷履歴', active: false, url: '' },
    { id: 5, name: '変更履歴', active: false, url: '' },
  ]
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  return (
    <>
    <div className='mt-4'>
       <div className="top-header bg-[#F3F4F6] flex px-10">
        <div className="top-header-menu-left flex h-full gap-4">
          <div className="top-header-menu-list middle-menu h-full flex flex-row">
            <ul className="list-none flex h-full items-center">
              {
                menus?.map((item,index)=> <li key={index} className={`${item.active ? 'bg-sky-100' : ''} hover:bg-sky-100 hover:font-bold focus:outline-sky-600`} ><Link to={item?.url} className="p-4 block font-bold">{item.name}</Link></li>)
              }
            </ul>
            <div className='p-4 text-[#6B7280]'>※ 詳細検索から検索ルールを保存できます。</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div className='py-4 bg-[#E5E7EB] px-10 flex gap-5'>
        <div className='search-dropdown relative'>
        <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='search-icon'>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 2.125C4.6434 2.125 2.125 4.6434 2.125 7.75C2.125 10.8566 4.6434 13.375 7.75 13.375C9.30347 13.375 10.7089 12.7461 11.7275 11.7275C12.7461 10.7089 13.375 9.30347 13.375 7.75C13.375 4.6434 10.8566 2.125 7.75 2.125ZM0.875 7.75C0.875 3.95304 3.95304 0.875 7.75 0.875C11.547 0.875 14.625 3.95304 14.625 7.75C14.625 9.42319 14.0266 10.9575 13.0333 12.1494L16.9419 16.0581C17.186 16.3021 17.186 16.6979 16.9419 16.9419C16.6979 17.186 16.3021 17.186 16.0581 16.9419L12.1494 13.0333C10.9575 14.0266 9.42319 14.625 7.75 14.625C3.95304 14.625 0.875 11.547 0.875 7.75Z" fill="#9CA3AF"/>
        </svg>
          <CustomDropdown options={dropdownOptions} selectedValue={selectedValue}/>
        </div>
        <button className="box-shadow-form h-9 min-w-20 rounded bg-[#0284C7] px-3 py-2 text-white focus:outline-emerald-600">検索</button>
        <button className="box-shadow-form h-9 min-w-20 rounded bg-[#047857] px-3 py-2 text-white focus:outline-emerald-600" >編集</button>
      </div>
    </div>
    <Table />
    </>
    
  )
}

export default LayoutSM3