import React, { useState } from 'react'

function ShippingCategory({dynamicLayout}) {
    const [activeLayout,setActiveLayout] = useState('layoutSM3');
    const showLayouts=(data)=>{
      dynamicLayout(data);
      setActiveLayout(data);
    }
  return (
    <div>
        <div className="px-10 pt-4 category-list-hide">
        <div className="category-list bg-white">
            <div className="category-list-block">
            <div className="flex flex-wrap gap-2">
                <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutSM1')}>
                <div className={`${activeLayout === 'layoutSM1' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                    <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white font-bold">
                    引当待
                    </p>
                    <p className="category-badge inline-block bg-sky-600 leading-5 px-2 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    20
                    </p>
                </div>
                </div>
                <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutSM2')}>
                <div className={`${activeLayout === 'layoutSM2' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                    <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white font-bold">
                    保留
                    </p>
                    <p className="category-badge inline-block bg-sky-600 leading-5 px-2 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    20
                    </p>
                </div>
                </div>
                <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutSM3')}>
                <div className={`${activeLayout === 'layoutSM3' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                    <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white font-bold">
                    印刷待
                    </p>
                    <p className="category-badge inline-block bg-sky-600 leading-5 px-2 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    20
                    </p>
                </div>
                </div>
                <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutSM4')}>
                <div className={`${activeLayout === 'layoutSM4' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                    <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white font-bold">
                    印刷済
                    </p>
                    <p className="category-badge inline-block bg-sky-600 leading-5 px-2 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    20
                    </p>
                </div>
                </div>
                <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutSM5')}>
                <div className={`${activeLayout === 'layoutSM5' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                    <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white font-bold">
                    検品済
                    </p>
                    <p className="category-badge inline-block bg-sky-600 leading-5 px-2 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    20
                    </p>
                </div>
                </div>
                <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutSM6')}>
                <div className={`${activeLayout === 'layoutSM6' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                    <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white font-bold">
                    出荷済
                    </p>
                    <p className="category-badge inline-block bg-sky-600 leading-5 px-2 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    20
                    </p>
                </div>
                </div>
                <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutSM7')}>
                <div className={`${activeLayout === 'layoutSM7' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                    <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white font-bold">
                    返品
                    </p>
                    <p className="category-badge inline-block bg-sky-600 leading-5 px-2 py-1 text-xs font-medium text-white group-hover:bg-white group-hover:text-sky-600 group-[.active]:bg-white group-[.active]:text-sky-600">
                    20
                    </p>
                </div>
                </div>
                <div className="category-list-info-main text-center cursor-pointer" onClick={()=> showLayouts('layoutSM8')}>
                <div className={`${activeLayout === 'layoutSM8' ? 'active !bg-sky-600' : ''} category-list-info group flex flex-col items-center justify-center rounded border-[2px] border-sky-600 bg-white py-[9px] hover:bg-sky-600`}>
                    <p className="category-text mb-1 text-xs text-gray-900 group-hover:text-white group-[.active]:text-white font-bold">
                    すべて
                    </p>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default ShippingCategory