import React, { useEffect } from 'react'
import Filter from './filter'
import Table from './table/Table'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function LayoutPM1() {
  useEffect(()=>{
    var chart = am4core.create("chartdiv", am4charts.XYChart);

    // Add data
    chart.data = [
       {
      "category": "実績ベース",
      "value": 10
      },
      {
      "category": "実績ベース",
      "value": 65
      },
       {
        "category": "計画ベース",
        "value": 52
      },
    ];
    
    am4core.color("red");
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
   
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = "value";
    series.dataFields.categoryY = "category";
    series.columns.template.fill = am4core.color("#0284C7"); // Red color
  })
  return (
    <>
      <Filter />
      <div className='mx-10 mb-4'>
        <div className='border border-gray-300 rounded flex gap-[20px] pr-[20px]'>
          <div id="chartdiv" style={{ width: "1200px", height: "178px" }} className=''></div>
          <div className='flex flex-col gap-[28px] pt-[15px]'>
            <div className='w-[98px] h-[42px]'>
              <button className="box-shadow-form h-full w-full rounded font-bold text-[#0284C7] border border-[#0284C7] text-center">
              750/1,500
              </button>
            </div>
            <div className='w-[98px] h-[42px]'>
              <button className="box-shadow-form h-full w-full rounded font-bold text-[#0284C7] border border-[#0284C7] text-center">
              750/1,200
              </button>
            </div>
          </div>
          <div className='relative top-[102px]'>
            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.87564 23L14 2L26.1244 23H1.87564Z" stroke="#0284C7" strokeWidth="2"/>
            </svg>
          </div>
        </div>
      </div>
     
      <Table />
    </>
    
  )
}

export default LayoutPM1