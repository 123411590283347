import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";

function Table() {
  const [dropdownOptions] = useState([
    { label: '処理を選択してください', value: 1 }
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全3件</span>
                  <div
                    className="custom-dropdown relative inline-block min-w-[200px] text-left" >
                    <div>
                      <CustomDropdown options={dropdownOptions} selectedValue={selectedValue}/>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
            <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
              <thead className="text-sm uppercase leading-custom font-medium">
                <tr className="border border-gray-200 bg-gray-100">
                  <th className="border bg-gray-100 px-2.5 py-3.5 w-[48px] font-medium">
                    <div className="flex items-center justify-center">
                      <input
                        id="checkbox_all"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="checkbox_all" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th className="border bg-gray-100 px-2.5 py-3.5 w-[150px] align-top font-medium">
                    支払方法
                  ID
                  </th>
                  <th className="border bg-gray-100 px-2.5 py-3.5 w-[150px] align-top font-medium">
                  種別
                  </th>
                  <th className="border bg-gray-100 px-2.5 py-3.5 w-[300px] align-top font-medium">
                    支払方法名
                  </th>
                  <th className="border bg-gray-100 px-2.5 py-3.5 w-[442px] align-top font-medium">
                    キーワード
                  </th>
                  <th className="border bg-gray-100 px-2.5 py-3.5 w-[150px] align-top font-medium">
                   利用
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                  >
                      3311
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                      標準
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  small packet(船便)
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">無効</td>
                </tr>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                  >
                     2000
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    標準
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  駿和物流
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">無効</td>
                </tr>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                  >
                      2001
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                     標準
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    エコ配
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">無効</td>
                </tr>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                  >
                      2002
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                      標準
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    トナミ運輸
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">無効</td>
                </tr>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                  >
                      2003
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                     標準
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  日本通運e発行
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">無効</td>
                </tr>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                  >
                    2004
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    標準
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  SBS即配
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">無効</td>
                </tr>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                  >
                    2005
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  標準
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  東京納品代行
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">無効</td>
                </tr>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                  >
                   2040
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                   標準
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  国際小包航空便
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">無効</td>
                </tr>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                  >
                   2371
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                   標準
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  宅急便コレクト
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">無効</td>
                </tr>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                  >
                   2372
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                   標準
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  EAZY
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">無効</td>
                </tr>
              </tbody>
            </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Table;
