import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";
import Modal from "../modal/Modal";

function Table() {
  const [dropdownOptions] = useState([
    { label: '削除', value: '' },
    { label: '処理を選択', value: 1 },
    { label: '返品入荷予定CSV出力', value: 2 },
    { label: 'バーコード印刷', value: 3 },
    { label: '手動格納', value: 4 },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全3件</span>
                  <div
                    className="custom-dropdown relative inline-block min-w-[200px] text-left" >
                    <div>
                      <CustomDropdown options={dropdownOptions} selectedValue={selectedValue}/>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                  <button className="box-shadow-form h-9 min-w-20 rounded bg-emerald-700 px-3 py-2 font-bold text-white focus:outline-emerald-600" onClick={handleOpenModal}>返品登録</button>
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
            <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
              <thead className="text-sm uppercase leading-custom font-medium">
                <tr className="border border-gray-200 bg-gray-100">
                  <th
                    className="border bg-gray-100 px-2.5 py-3.5 w-[48px] font-medium"
                    rowSpan="3"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="checkbox_all"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="checkbox_all" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th
                    className="border bg-gray-100 px-2.5 py-3.5 w-[64px] align-top font-medium"
                    rowSpan="3"
                  >
                    操作
                  </th>
                  <th className="border bg-gray-100 px-2.5 py-3.5 w-[89px] align-top font-medium">
                    出荷日
                  </th>
                  <th className="border bg-gray-100 px-2.5 py-3.5 w-[106px] align-top font-medium">
                    注文番号
                  </th>
                  <th
                    className="border bg-gray-100 px-2.5 py-3.5 w-[111px] align-top font-medium"
                    rowSpan="3"
                  >
                    品番 <br/> バーコード
                  </th>
                  <th
                    className="border bg-gray-100 px-2.5 py-3.5 w-[95px] align-top font-medium"
                    rowSpan="3"
                  >
                    商品名
                  </th>
                  <th
                    className="border bg-gray-100 px-2.5 py-3.5 w-[48px] align-top font-medium"
                    rowSpan="3"
                  >
                    数量
                  </th>
                  <th
                    className="border bg-gray-100 px-2.5 py-3.5 w-[60px] align-top font-medium"
                    rowSpan="3"
                  >
                    状態
                  </th>
                  <th className="border bg-gray-100 px-2.5 py-3.5 w-[127px] align-top font-medium">
                    配送方法（返品）
                  </th>
                  <th
                    className="border bg-gray-100 px-2.5 py-3.5 w-[210px] align-top font-medium"
                    rowSpan="3"
                  >
                    返品理由
                  </th>
                  <th
                    className="border bg-gray-100 px-2.5 py-3.5 w-[210px] align-top font-medium"
                    rowSpan="3"
                  >
                    備考
                  </th>
                  <th
                    className="border bg-gray-100 px-2.5 py-3.5 w-[72px] align-top font-medium"
                    rowSpan="3"
                  >
                    作業者
                  </th>
                </tr>
                <tr className="border border-gray-200 bg-gray-100">
                  <th className="border px-2.5 py-3.5 align-top font-medium">
                    登録日
                  </th>
                  <th className="border px-2.5 py-3.5 align-top font-medium">
                    注文者
                  </th>
                  <th className="border px-2.5 py-3.5 align-top font-medium">
                    着払送料（返品）
                  </th>
                </tr>
                <tr className="border border-gray-200 bg-gray-100">
                  <th className="border px-2.5 py-3.5 align-top font-medium">
                    更新日
                  </th>
                  <th className="border px-2.5 py-3.5 align-top font-medium">
                    送付先
                  </th>
                  <th
                    className="border px-2.5 py-3.5 align-top font-medium"
                    colSpan="2"
                  >
                    送り状No.（返品）
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-2"
                    rowSpan="3"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-2 text-left"
                    rowSpan="3"
                  >
                    <button className="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">
                      編集
                    </button>
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    2024/02/03
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    24-111-1111-1
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                    TCT001
                    <br />
                    4912345123459
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                    アパレルAAA
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top text-right"
                    rowSpan="3"
                  >
                    1
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                    良品
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    24-111-1111-3
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                    受取拒否
                  </td>
                  <td className="border px-2.5 py-3.5 align-top" rowSpan="3"></td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                    伊藤太郎
                  </td>
                </tr>
                <tr className="border border-gray-200 ">
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    2024/02/06
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    伊藤太郎
                  </td>
                  <td className="border  border-gray-200 px-2.5 py-3.5 text-right">
                    880
                  </td>
                </tr>
                <tr className="border border-gray-200 ">
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    2024/02/07
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 bg-gray-100"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    1234567890
                  </td>
                </tr>
                {/*row-2*/}
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-3.5"
                    rowSpan="3"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 text-left"
                    rowSpan="3"
                  >
                    <button className="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">
                    編集
                    </button>
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  2024/02/03
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  24-111-1111-2
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                   TCT002
                    <br />
                    4912345123460
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                    雑貨ABC
                  </td>
                  <td
                    className="border border-gray-200 align-top"
                    rowSpan="3"
                  >
                   <p className="px-2.5 py-3.5 h-[69px] text-right">1</p> <p className="px-2.5 py-3.5 h-[69px] text-right border-t">1</p>
                  </td>
                  <td
                    className="border border-gray-200  align-top"
                    rowSpan="3"
                  >
                   <p className="px-2.5 py-3.5 h-[69px]">良品</p> <p className="px-2.5 py-3.5 h-[69px] border-t">不良品</p>
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  24-111-1111-3
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                   イメージ違い
                  </td>
                  <td className="border px-2.5 py-3.5 align-top" rowSpan="3">着払いらくらく交換</td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                   伊藤太郎
                  </td>
                </tr>
                <tr className="border border-gray-200 ">
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  2024/02/06
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  鈴木太郎
                  </td>
                  <td className="border  border-gray-200 px-2.5 py-3.5 text-right bg-gray-100">
                    
                  </td>
                </tr>
                <tr className="border border-gray-200 ">
                  <td className="border border-gray-200 px-2.5 py-3.5 bg-gray-100">
                  
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 bg-gray-100"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    1234567891
                  </td>
                </tr>
                {/*row -3*/}
                <tr className="border border-gray-200 bg-white">
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 "
                    rowSpan="3"
                  >
                    <div className="flex items-center justify-center">
                      <input
                        id="1"
                        className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600"
                        type="checkbox"
                      />
                      <label htmlFor="1" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 text-left"
                    rowSpan="3"
                  >
                    <button className="box-shadow-form h-6 rounded bg-emerald-700 px-1 py-1 font-medium text-white focus:outline-emerald-600 min-w-[40px]">
                      編集
                    </button>
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  2024/02/03
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  24-111-1111-3
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                    TCT003
                    <br />
                    4912345123461
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                    書籍CCC
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top text-right"
                    rowSpan="3"
                  >
                    1
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  ></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                    24-111-1111-3
                  </td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                    その他
                  </td>
                  <td className="border px-2.5 py-3.5 align-top" rowSpan="3"></td>
                  <td
                    className="border border-gray-200 px-2.5 py-3.5 align-top"
                    rowSpan="3"
                  >
                    伊藤太郎
                  </td>
                </tr>
                <tr className="border border-gray-200">
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  2024/02/06
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  山田太郎
                  </td>
                  <td className="border border-gray-200 px-2.5 py-3.5 bg-gray-100"></td>
                 
                </tr>
                <tr className="border border-gray-200">
                  <td className="border border-gray-200 px-2.5 py-3.5 bg-gray-100"></td>
                  <td className="border border-gray-200 px-2.5 py-3.5">伊藤次郎</td>
                  <td className="border border-gray-200 px-2.5 py-3.5">
                  1234567892
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onClose={handleCloseModal} title="My Modal">
       </Modal>
    </div>
  );
}

export default Table;
