import React, { useState } from 'react'
import SetProductCategory from '../components/category/SetProductCategory'
import LayoutP1 from '../mvp2/03-34';
import LayoutP2 from '../mvp2/03-35';
import LayoutP3 from '../mvp2/03-36';


function SetProductRender() {
    const [show, setShow] = useState('layoutP1');
    const handleDynamicLayout=(data)=>{
      setShow(data);
    };
  return (
    <>
    <SetProductCategory dynamicLayout={handleDynamicLayout}/>
     {show === 'layoutP1' && <LayoutP1 />}
     {show === 'layoutP2' && <LayoutP2 />}
     {show === 'layoutP3' && <LayoutP3 />}
    </>
  )
}

export default SetProductRender