import React from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'


const CustomDropdown = ({ options, selectedValue, disabled, onChange }) => {
  return (
    <Listbox value={selectedValue} onChange={onChange}>
      <div className="relative w-full">
        <Listbox.Button tabIndex={disabled ? -1 : 0} className={`relative z-0 w-full cursor-pointer rounded border border-gray-300 py-2 pl-3 pr-8 text-left shadow-sm ${!disabled ? 'bg-white focus:outline-sky-600 aria-expanded:border-sky-600 aria-expanded:ring-1 aria-expanded:ring-sky-600' : 'bg-gray-100'}`}>
          <span className={`block truncate ${selectedValue.value === '' ? 'text-gray-400' : ''}`}>{selectedValue.label}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        {!disabled && (
          <Transition as={React.Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-20 mt-2.5 max-h-60 w-full min-w-fit overflow-auto rounded border border-gray-300 bg-white text-xs shadow-md focus:outline-none">
              {options.map((val, index) => (
                <Listbox.Option key={index} className={({ active }) => `relative cursor-default select-none px-3 py-2 ${active ? 'bg-sky-600 text-white' : 'text-gray-900'}`} value={val}>
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{val.label}</span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        )}
      </div>
    </Listbox>
  )
}

export default CustomDropdown
