import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";

function Table() {
  const [dropdownOptions] = useState([
    { label: '削除', value: '' },
    { label: '処理を選択', value: 1 },
    { label: '返品入荷予定CSV出力', value: 2 },
    { label: 'バーコード印刷', value: 3 },
    { label: '手動格納', value: 4 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '表示切替', value: 0 },
    { label: '表示切替', value: 1 }
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[0]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
 
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全2件</span>
                  <div
                    className="custom-dropdown relative inline-block min-w-[200px] text-left" >
                    <div>
                      <CustomDropdown options={dropdownOptions} selectedValue={selectedValue}/>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                    <div className="w-[98px]">
                      <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
                    </div>
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr className="h-[34px]">
                    <th className="border bg-gray-100 px-3 py-2 w-[48px] font-medium">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="checkbox_all" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[41px] align-top font-bold">
                    No
                    </th>
                    <th className="border bg-gray-100 px-3 py-2  w-[85px] align-top font-bold">
                    ステータス
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[84px] align-top font-bold">
                    API状況 
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[96px] align-top font-bold">
                    注文者名
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[100px] align-top font-bold">
                    注文番号
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[140px] align-top font-bold">
                    出荷予定日
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[140px] align-top font-bold">
                    印刷日時
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[140px] align-top font-bold">
                    配送指定日
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[84px] align-top font-bold">
                    配送方法
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[77px] align-top font-bold">
                    決済方法
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[108px] align-top font-bold">
                    送り状番号
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[96px] align-top font-bold">
                    <button type="button" className="w-[32px] h-[19px] bg-sky-600 text-white me-2">個口</button>
                    サイズ
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white hover:bg-gray-100">
                   <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    91
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    山田よしひこ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    1139601
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    ゆうパック
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    カード
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    182761878881
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    <button type="button" className="w-[32px] h-[19px] bg-sky-600 text-white me-2">1</button>
                    60
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                   <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    92
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    山田よしひこ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    1139602
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    ゆうパック
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    NP後払い
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    182761878882
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                      <button type="button" className="w-[32px] h-[19px] bg-sky-600 text-white me-2">1</button>
                      60
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                   <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    93
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    山田よしひこ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    1139603
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    ゆうパック
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    代引き
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    182761878883
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    <button type="button" className="w-[32px] h-[19px] bg-sky-600 text-white me-2">1</button>
                    60
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                   <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    94
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    山田よしひこ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    1139604
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    ゆうパック
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    代引き
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    182761878884
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    <button type="button" className="w-[32px] h-[19px] bg-sky-600 text-white me-2">1</button>
                    60
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                   <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    95
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    山田よしひこ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    1139605
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    ゆうパック
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    代引き
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    182761878885
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    <button type="button" className="w-[32px] h-[19px] bg-sky-600 text-white me-2">1</button>
                    60
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                   <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    96
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    山田よしひこ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    1139606
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    ゆうパック
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    代引き
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    182761878886
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    <button type="button" className="w-[32px] h-[19px] bg-sky-600 text-white me-2">1</button>
                    60
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                   <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    97
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    山田よしひこ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    1139607
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    ゆうパック
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    代引き
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    182761878887
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    <button type="button" className="w-[32px] h-[19px] bg-sky-600 text-white me-2">1</button>
                    60
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100">
                   <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 text-left align-top">
                    98
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    出荷済
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    山田よしひこ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    1139608
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024/01/23 0815
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    ゆうパック
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    代引き
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    182761878888
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    <button type="button" className="w-[32px] h-[19px] bg-sky-600 text-white me-2">1</button>
                    60
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        
    </div>
  );
}

export default Table;
