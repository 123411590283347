import React from 'react'
import Filter from './filter/index';
import Table from './table/Table';

function LayoutNew9() {
  return (
    <>
    <Filter />
    <Table />
  </>
  )
}

export default LayoutNew9