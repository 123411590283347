import { useState } from "react";
import CustomPicker from "../../../components/layout/datepicker";
import CustomDropdown from "../../../components/layout/dropdown";
const Modal = ({ show, onClose, title, children }) => {
  const [dropdownOptions1] = useState([
    { label: 'エリア​', value: 0 },
  ]);
  const [dropdownOptions2] = useState([
    { label: 'ゾーン​', value: 0 },
  ]);
  const [dropdownOptions3] = useState([
    { label: 'ロケレベル​', value: 0 },
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[0]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[0]);
  const [selectedValue3, setSelectedValue3] = useState(dropdownOptions3[0]);
  if (!show) {
    return null;
  }

  return (
    <div className="fixed w-full h-full inset-0 flex justify-center bg-gray-800 bg-opacity-20 z-50 overflow-auto">
      <div className="w-full max-w-[400px] h-[445px] my-5 transform rounded bg-white px-10 py-5 text-left align-middle shadow-md transition-all">
          <div className="modal-header relative flex items-center justify-between">
            <p className="text-xl font-medium text-gray-900">新規登録</p>
            <button
              type="button"
              onClick={onClose}
              className="ml-auto inline-flex items-center rounded-lg bg-white text-gray-400 hover:text-gray-900 focus:outline-sky-600"
            >
              <span className="sr-only">Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="h-8 w-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                ></path>
              </svg>
            </button>
          </div>
          <div className="modal-body mt-5">
            <div className="relative">
            <div className="form-group flex-wrap">
        <div className="mb-4">
          <div className="w-full">
          <p className="mb-2">エリア​</p>
          <CustomDropdown options={dropdownOptions1} selectedValue={selectedValue1}/>
          </div>
        </div>
      </div>

      <div className="form-group flex-wrap">
        <div className="mb-4">
          <div className="w-full">
          <p className="mb-2">ゾーン</p>
          <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
          </div>
        </div>
      </div>
      <div className="form-group flex-wrap">
        <div className="mb-4">
          <div className="w-full">
          <p className="mb-2">ロケーション <span className="text-rose-600">必須</span>​</p>
          <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="ロケーション​" type="text" />
          </div>
        </div>
      </div>
      <div className="form-group flex-wrap">
        <div className="mb-4">
          <div className="w-full">
          <p className="mb-2">ロケレベル​</p>
          <CustomDropdown options={dropdownOptions3} selectedValue={selectedValue3}/>
          </div>
        </div>
      </div>
            </div>
          </div>
          <div className="modal-footer mt-5">
            <ul className="flex flex-wrap justify-center space-x-5">
              <li className="w-[80px] h-[34px]">
                <button className="box-shadow-form h-full w-full rounded bg-sky-600 px-3 font-medium text-white focus:outline-sky-600">
                登録
                </button>
              </li>
              <li className="w-[80px] h-[34px]">
                <button
                  type="button"
                  className="box-shadow-form h-full w-full font-medium rounded border border-gray-300 bg-white px-3 focus:outline-sky-600"
                >
                  閉じる
                </button>
              </li>
            </ul>
          </div>
      </div>
    </div>
  );
};
 
export default Modal;