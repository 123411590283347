import App from '../App';
import DataRender from '../data-render/DataRender';
import Layout4 from '../mvp2/0625';
import NoData from '../components/NoData';
import { Navigate } from 'react-router-dom';
import Layout6 from '../mvp2/0614-6';
import SetManagementRender from '../data-render/SetManagementRender';
import SetProductRender from '../data-render/SetProductRender';
import MobileRender from '../data-render/MobileRender';
import LayoutPS1 from '../mvp2/0625-3';
import LayoutBR from '../mvp2/0625-5';
import LayoutOL from '../mvp2/0625-10';
import LayoutEL from '../mvp2/0625-9';
import ProgressRender from '../data-render/ProgressRender';
import LayoutNew9 from '../mvp2/0624-9';
import LayoutNew7 from '../mvp2/0624-7';
import LayoutNew6 from '../mvp2/0624-6';
import LayoutNew5 from '../mvp2/0624-5';
import LayoutNew1 from '../mvp2/0624-1';
import LayoutNew2 from '../mvp2/0624-2';
import LayoutNew3 from '../mvp2/0624-3';
import { ShippingRender } from '../data-render/ShippingRender';
import LayoutNewCh1 from '../mvp2/0537-1';
import LayoutNewCh2 from '../mvp2/0537-2';
import LayoutNewCh3 from '../mvp2/0537-3';
import LayoutNewCh4 from '../mvp2/0537-4';
import LayoutNewCh5 from '../mvp2/0537-5';

const router = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '',
                element: <Navigate to="/business/arrival-management/return-shipment" replace />
            },
            {
                path: 'warehouse',
                children:[
                    {
                        path: 'progress-management',
                        element: <ProgressRender />
                    },
                    {
                        path: 'print-list',
                        element: <MobileRender />
                    },
                    {
                        path: 'print-schedule',
                        element: <LayoutPS1 />
                    },
                    {
                        path: 'batch-rules',
                        element: <LayoutBR />
                    },
                    {
                        path: 'operation-log',
                        element: <LayoutOL />
                    },
                    {
                        path: 'error-log',
                        element: <LayoutEL />
                    }
                ]
            },
            {
                path: 'business',
                children:[
                    {
                        path: 'arrival-management',
                        children: [
                            {
                                path: 'return-shipment',
                                element: <DataRender />
                            }
                        ]
                    },
                    {
                        path: 'shipping-management',
                        element: <ShippingRender />
                    },
                    {
                        path: 'set-management',
                        children: [
                            {
                                path: 'set-master',
                                element: <Layout4 />
                            },
                            {
                                path: 'set-product',
                                element: <SetProductRender />
                            },
                            {
                                path: 'set-disassembly',
                                element: <SetManagementRender />
                            }
                        ]
                    },
                    {
                        path: 'return-management',
                        children: [
                            {
                                path: 'return-manage',
                                element: <Layout6 />
                            }
                        ]
                    },
                ]
            },
            {
                path: 'system',
                children:[
                    {
                        path: 'master',
                        children: [
                            {
                                path: 'customer',
                                element: <LayoutNew1 />
                            },
                            {
                                path: 'ships-form',
                                element: <LayoutNew2 />
                            },
                            {
                                path: 'box-master',
                                element: <LayoutNew3 />
                            },
                            {
                                path: 'delivery-method',
                                element: <LayoutNew5 />
                            },
                            {
                                path: 'delivery-time',
                                element: <LayoutNew6 />
                            },
                            {
                                path: 'payment-method',
                                element: <LayoutNew7 />
                            },
                            {
                                path: 'delivery-node-settings',
                                element: <LayoutNew9 />
                            },
                        ]
                    },
                ]
            },
            {
                path: 'inventory-management',
                children:[
                    {
                        path: 'setting',
                        children: [
                            {
                                path: 'location-master',
                                element: <LayoutNewCh1 />
                            },
                            {
                                path: 'area-master',
                                element: <LayoutNewCh2 />
                            },
                            {
                                path: 'product-classification',
                                element: <LayoutNewCh3 />
                            },
                            {
                                path: 'inventory-classification',
                                element: <LayoutNewCh4 />
                            },
                            {
                                path: 'location-level',
                                element: <LayoutNewCh5 />
                            },
                        ]
                    },
                ]
            },
            {
                path: '*',
                element: <NoData />
            }
        ]
    }
];

export default router;