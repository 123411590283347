import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";
import Modal from "../modal/Modal";

function Table() {
  const [dropdownOptions] = useState([
    { label: '処理を選択', value: '' },
    { label: '無効化', value: 1 },
    { label: '有効化', value: 2 },
    { label: '削除', value: 3 },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全2件</span>
                  <div
                    className="custom-dropdown relative inline-block min-w-[200px] text-left" >
                    <div>
                      <CustomDropdown options={dropdownOptions} selectedValue={selectedValue}/>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                    <div>
                      <button className="box-shadow-form h-9 min-w-[72px] rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600" onClick={() => handleOpenModal()}>
                        新規登録
                      </button>
                    </div>
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr className="h-[34px]">
                    <th className="border bg-gray-100 px-3 py-2 w-[48px] font-medium">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="checkbox_all" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[300px] font-medium">
                    荷主（荷主ID）
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[48px] font-medium">
                    回数
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[72px] font-medium">
                    印刷時間
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[150px] font-medium">
                     スケジュール名称
                    </th>
                    <th className="border bg-gray-100 px-3 py-2  w-[72px] font-medium">
                    印刷種類
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[103px] font-medium">
                    ルール名
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[36px] font-medium">
                    月
                    </th>
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[36px] font-medium">
                    火
                    </th>
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[36px] font-medium">
                    水
                    </th>
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[36px] font-medium">
                    木
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[36px] text-center font-medium">
                    金
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[36px] font-medium">
                    土
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[36px] font-medium">
                    日
                    </th>
                    <th className="border bg-gray-100 px-3 py-2  w-[36px] font-medium">
                    祝
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[60px] font-medium">
                    休業日
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[136px] font-medium">
                    処理
                    </th>
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[48px] font-medium">
                    有効
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="break-all px-3 py-2 align-top">
                      <p>ABC書店</p>
                      <p className="mt-1">(1002)</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                        1
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    09:00
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      朝一作業分
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    バッチ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                    オートシップ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                      <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>02/27</p>
                      <p className="mt-1">03/03</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                        <button className="box-shadow-form h-[22px] min-w-[64px] rounded bg-sky-600 px-2 font-bold text-white focus:outline-sky-600">
                       手動印刷
                      </button>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">有効</td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="px-3 py-2 align-top">
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                        2
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    11:00
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    午前2回目
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    バッチ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                    オートシップ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                      <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                       <button className="box-shadow-form h-[22px] min-w-[64px] rounded bg-sky-600 px-2 font-bold text-white focus:outline-sky-600">
                       手動印刷
                      </button>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">有効</td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="break-all px-3 py-2 align-top">
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top bg-[#FEF9C3]">
                        3
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top bg-[#FEF9C3]">
                    13:30
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top bg-[#FEF9C3]">
                    当日分最終
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top bg-[#FEF9C3]">
                    抽出
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer bg-[#FEF9C3]">
                    沖縄・離島
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                      <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                      <button className="box-shadow-form h-[22px] min-w-[64px] rounded bg-sky-600 px-2 font-bold text-white focus:outline-sky-600">
                       手動印刷
                      </button>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">有効</td>
                  </tr>
                  <tr className="bg-gray-100 hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>Xアパレル</p>
                      <p className="mt-1">(1003)</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top bg-[#FEF9C3] text-[#9CA3AF]">
                        1
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top bg-[#FEF9C3] text-[#9CA3AF]">
                    10:00
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top text-[#9CA3AF] bg-[#FEF9C3]">
                    スケジュールテスト
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top bg-[#FEF9C3] text-[#9CA3AF]">
                    バッチ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top bg-[#FEF9C3] text-[#9CA3AF]">
                    オートシップ2
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                      <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#9CA3AF]">停止</td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[70px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>BBB雑貨</p>
                      <p className="mt-1">(1004)</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                        1
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    11:00
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    配送指定日優先
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    バッチ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                    オートシップ3
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                      <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    <div className="w-[12px] h-[12px] bg-[#4B5563E5] rounded-[50%]"></div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-middle">
                      <button className="box-shadow-form h-[22px] min-w-[64px] rounded bg-sky-600 px-2 font-bold text-white focus:outline-sky-600">
                       手動印刷
                      </button>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">有効</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onClose={handleCloseModal} title="My Modal">
       </Modal>
    </div>
  );
}

export default Table;
