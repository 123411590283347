import "./Header.css";
import headerlogo from "../images/logigram-logo.svg";
import CustomDropdown from "../components/layout/dropdown";
import { Link, useLocation} from "react-router-dom";
import { useEffect, useState } from "react";
import SubmenuSecond from "./menus/submenu-second";
import SubmenuFirst from "./menus/submenu-first";
import {initialSubheader,mainHeader} from '../constants/subHeaderData';
import alerticon from "../images/alert-icon.svg";
import closeicon from "../images/x-mark.svg";

function Header() {
  const location = useLocation(); 
  const [url, setUrl] = useState(null);
  const [dropdownOptions1] = useState([
    { label: '第1倉庫', value: 0 },
    { label: '第2倉庫', value: 1 },
    { label: '第3倉庫', value: 2 },
    { label: '第4倉庫', value: 3 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '1007 コマースロボティクス', value: 0 },
    { label: '1008 コマースロボティクス', value: 1 },
    { label: '1009 コマースロボティクス', value: 2 },
    { label: '1010 コマースロボティクス', value: 3 },
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[0]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[0]);
  const [subheader2,setSubheader2] = useState(initialSubheader[2]?.submenu);
  const [mainMenu,setMainMenu] = useState(mainHeader[1]?.mainSubmenu[0]);
  const [notify,setNotify] = useState(false);
  useEffect(() => {
    let path = location.pathname.replace(/^\//, '');
    setUrl(path);
  }, [location]);
  const getMenuData = (menus)=> {
    setSubheader2(menus?.submenu || []);
  }
  const handleClick= (data)=> {
    setMainMenu(data[0])
  }
  return (
    <>
    {
      notify && (
      <div className="w-[288px] h-[56px] shadow-md border border-gray-200 bg-white rounded-md p-4 fixed top-[50px] right-[178px]">
        <div className="flex">
            <img src={alerticon} className="w-[24px] h-[24px]"></img>
            <p className="my-auto mx-2 text-sm w-[400px]">「セット組制作指示」があります！</p>
            <img src={closeicon} className="w-[20px] h-[20px] my-auto cursor-pointer" onClick={() => setNotify(false)}></img>
        </div>
      </div>
      )
    }
     <div className="header-main">
      <div className="top-header bg-[#C2EBFF] flex h-[55px] px-5">
        <div className="top-header-menu-left flex h-full gap-4">
          <a href="#" className="flex items-center">
            <img src={headerlogo} className="h-5" />
          </a>
          <div className="top-header-menu-list top-menu h-full">
            <ul className="list-none flex h-full items-center ">
              {
                mainHeader.map((header,index)=>
                  <li key={index} className={`${url?.includes(header?.url) ? 'active' : ''} px-4 py-5 px-4 py-5 hover:bg-[#09A1EC] hover:font-bold focus:outline-sky-600 h-full`} onClick={()=>handleClick(header.mainSubmenu)}><Link to={header.url} className="block font-bold">{header.name}</Link></li>
                )
              }
            </ul>
          </div>
        </div>
        <div className="header-right py-2.5 my-auto ms-auto">
          <div className="flex items-center">
            <div className="mr-4 w-[140px]">
              <div className="custom-dropdown relative inline-block w-full text-left">
                <div>
                  <CustomDropdown options={dropdownOptions1} selectedValue={selectedValue1}/>
                </div>
              </div>
            </div>
            <div className="w-[203px]">
              <div className="custom-dropdown relative inline-block w-full text-left">
                <div>
                  <CustomDropdown options={dropdownOptions2} selectedValue={selectedValue2}/>
                </div>
              </div>
            </div>
            <div className="ml-5 flex items-center space-x-2">
              <button className="focus:outline-sky-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="h-6 w-6 text-gray-400"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <button className="focus:outline-sky-600 relative" onClick={() => setNotify(!notify)}>
               <div className="border border-sky-50 bg-rose-600 w-[15px] h-[15px] absolute text-center leading-[14px] rounded-full right-[-3px] top-[-6px]">
                   <p className="m-0 text-white text-[10px]">1</p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="h-6 w-6 text-gray-400"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <button className="focus:outline-sky-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="h-6 w-6 text-gray-400"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 6a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3V6ZM3 15.75a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-2.25Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3v-2.25Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <button className="flex h-9 w-[100%] items-center rounded border border-box03 bg-white px-4 text-left focus:outline-sky-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  className="mr-1 h-5 w-5 text-sky-600"
                >
                  <path
                    fillRule="evenodd"
                    d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="leading-none">伊藤彰弘</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <SubmenuFirst menusData={getMenuData} setMenus={mainMenu}/>
      <SubmenuSecond subheader2={subheader2}/>
    </div>
    </>
   
  );
}

export default Header;
