import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";

function Table() {
  const [dropdownOptions] = useState([
    { label: '削除', value: '' },
    { label: '処理を選択', value: 1 },
    { label: '返品入荷予定CSV出力', value: 2 },
    { label: 'バーコード印刷', value: 3 },
    { label: '手動格納', value: 4 },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[1]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
  const [showModal, setShowModal] = useState(false);
 
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全2件</span>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr className="h-[78px]">
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[189px] align-top font-medium">
                    <p>バッチID</p>
                    <p className="my-1">荷主</p>
                    <p>荷主ID</p>
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[60px] align-top font-medium">
                      種類
                    </th>
                    <th className="border bg-gray-100 px-3 py-2  w-[48px] align-top font-medium">
                      品番
                    </th>
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[96px] align-top font-medium">
                      <p>注文数</p>
                      <p className="text-[#E11D48] mt-1">（エラー数）</p>
                    </th>
                    <th className="break-all border bg-gray-100 px-3 py-2 w-[89px] align-top font-medium">
                      <p>作成日</p>
                      <p className="my-1">開始時間</p>
                      <p>終了時間</p>
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[189px] align-top font-medium">
                    トータルピック
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[189px] align-top font-medium">
                      納品書
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[189px] align-top font-medium">
                      送り状
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[189px] align-top font-medium">
                      後払い
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[80px] align-top font-medium">
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white hover:bg-gray-100 h-[78px]">
                    <td className="break-all border border-gray-200 px-3 py-2 text-left align-top">
                      <p className="text-sky-600 underline focus:outline-sky-600 cursor-pointer cursor-pointer">240501-1002-2</p>
                      <p className="my-1">ABC書店（1002）</p>
                      <p>午前2回目</p>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>印刷</p>
                      <p className="mt-1">種</p>
                    </td>
                    <td className="border border-gray-200 px-3 text-right py-2 align-top">
                      2
                    </td>
                    <td className="border border-gray-200 px-3 text-right py-2 align-top">
                      200
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>2024/05/01</p>
                      <p className="my-1">11:00:01</p>
                      <p>11:02:15</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                      <p>T1002-2-neko-11.pdf</p>
                      <p>T1002-2-SG-D.pdf</p>
                      <p>T1002-2-SG-D.pdf</p>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top cursor-pointer">
                      <p className="text-sky-600 underline focus:outline-sky-600">N10022neko-11.pdf</p>
                      <p className="underline text-[#9CA3AF] my-1">N10022sagawa-d.pdf</p>
                      <p className="underline text-[#9CA3AF]">N10022sagawa-d.pdf</p>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">
                      <p>I10022neko11.csv</p>
                      <p className="my-1">I10022sagawa-d.pdf</p>
                      <p>I10022sagawa-d.pdf</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer">A10022NP.pdf</td>
                    <td className="border border-gray-200 px-3 text-center py-2 align-top">
                      <button className="box-shadow-form h-8 min-w-[40px] rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                        取消
                     </button>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[78px]">
                    <td className="break-all border border-gray-200 px-3 py-2 text-left align-top">
                      <p className="text-sky-600 underline focus:outline-sky-600 cursor-pointer cursor-pointer">240501-1002-1</p>
                      <p className="my-1">ABC書店（1002）</p>
                      <p>朝一作業分</p>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      バッチ
                    </td>
                    <td className="border border-gray-200 px-3 text-right py-2 align-top">
                      1
                    </td>
                    <td className="border border-gray-200 px-3 text-right py-2 align-top">
                      100
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>2024/05/01</p>
                      <p className="my-1">09:00:01</p>
                      <p>09:01:30</p>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer cursor-pointer">
                      <p>T1002-2-neko-11.pdf</p>
                      <p className="my-1">T1002-2-SG-D.pdf</p>
                      <p>T1002-2-SG-D.pdf</p>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 text-center py-2 align-top">
                      <button className="box-shadow-form h-8 min-w-[40px] rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                        取消
                    </button>
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-gray-100 h-[78px]">
                    <td className="break-all border border-gray-200 px-3 py-2 text-left align-top">
                      <p className="text-sky-600 underline focus:outline-sky-600 cursor-pointer cursor-pointer">240501-1002-1</p>
                      <p className="my-1">BBB雑貨（1003）</p>
                      <p>配送指定日優先</p>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                     抽出
                    </td>
                    <td className="border border-gray-200 px-3 text-right py-2 align-top">
                      1
                    </td>
                    <td className="border border-gray-200 px-3 text-right py-2 align-top">
                      50
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top">
                      <p>2024/05/01</p>
                      <p className="my-1">09:00:01</p>
                      <p>09:01:30</p>
                    </td>
                    <td className="break-all border border-gray-200 px-3 py-2 align-top text-sky-600 underline focus:outline-sky-600 cursor-pointer cursor-pointer">
                      <p>T1002-2-neko-11.pdf</p>
                      <p className="my-1">T1002-2-SG-D.pdf</p>
                      <p>T1002-2-SG-D.pdf</p>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 py-2 align-top"></td>
                    <td className="border border-gray-200 px-3 text-center py-2 align-top">
                      <button className="box-shadow-form h-8 min-w-[40px] rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                        取消
                    </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Table;
