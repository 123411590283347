import React, { useState } from 'react'
import CustomDropdown from '../../../components/layout/dropdown'

function Filter() {
  const [dropdownOptions1] = useState([
    { label: '', value: 0 },
    { label: '荷主名', value: 1 },
  ]);
  const [dropdownOptions2] = useState([
    { label: '', value: 0 },
    { label: '印刷種類', value: 1 },
  ]);
  const [dropdownOptions3] = useState([
    { label: '', value: 0 },
    { label: '2月8日（当日）', value: 1 },
  ]);
  const [selectedValue1, setSelectedValue1] = useState(dropdownOptions1[1]);
  const [selectedValue2, setSelectedValue2] = useState(dropdownOptions2[1]);
  const [selectedValue3, setSelectedValue3] = useState(dropdownOptions3[1]);

  const handleSelectChange = (newValue, setter) => {
    setter(newValue)
  }
  return (
    <div>
      <div className="px-10">
        <div className="py-4">
            <div className="form-group -mx-5 -mb-4 flex flex-wrap">
              <div className="mb-4 px-5">
                  <div className="w-[320px]">
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="エリア" type="text" />
                  </div>
              </div>
              <div className="mb-4 px-5">
                  <div className="w-[320px]">
                  <input className="box-shadow-form h-9 w-full rounded border border-gray-300 px-3 py-2 text-xs text-gray-900 placeholder:text-gray-400 focus:border-sky-600 focus:ring-sky-600" placeholder="荷主" type="text" />
                  </div>
              </div>
              <div className="mb-4 pl-5 flex">
                <div className="flex">
                  <div className="w-[72px]">
                    時間経過率：
                    13:00
                  </div>
                </div>
              </div>
              <div className="mb-4 flex pl-2">
                <div className='w-[100px] h-[64px]'>
                  <button className="box-shadow-form h-full w-full rounded font-bold text-gray-900 border border-[#D1D5DB] text-[32px] text-right pr-3">
                  60
                  </button>
                </div>
                <p className='mb-0 flex items-end ml-2'>%</p>
              </div>
            </div>
        </div>
        
      </div>
    </div>
  )
}

export default Filter